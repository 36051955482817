import {
    SOLICITATION_CREATE_SUCCESS,
    SOLICITATION_CHANGE_OPERATION_STATES,
    SERVICE_LOAD_SUCCESS,
    CHANGE_CURRENT_STEP,
    PROGRESS_LOAD_SUCCESS,
} from './reducer';
import qs from 'qs'

import { api } from "services/api";
import { toast } from 'react-toastify';
import { localizedStrings } from 'constants/localizedStrings';
import { format, parseISO } from 'date-fns';
import { updatePlaceExceptionPerService } from 'store/modules';
import { getPendentServices, getValidExceptions } from 'utils/services';

export function changeStep(
    step
) {
    return {
        type: CHANGE_CURRENT_STEP,
        payload: {
            stepIndex: step
        }
    };
}

export function serviceLoadSuccess({
    ...states
}) {
    return {
        type: SERVICE_LOAD_SUCCESS,
        payload: {
            ...states
        }
    };
}
export function progressLoadSuccess({
    ...states
}) {
    return {
        type: PROGRESS_LOAD_SUCCESS,
        payload: {
            ...states
        }
    };
}


export function serviceCreateSuccess(service) {
    return {
        type: SOLICITATION_CREATE_SUCCESS,
        payload: {
            service
        }
    };
}


export function serviceChangeOperationStates({
    loadLoading = false,
    loadSuccess = false,
    loadFail = false,
    updateLoading = false,
    updateSuccess = false,
    updateFail = false,
}) {
    return {
        type: SOLICITATION_CHANGE_OPERATION_STATES,
        payload: {
            loadLoading,
            loadSuccess,
            loadFail,
            updateLoading,
            updateSuccess,
            updateFail,
        }
    };
}

export const createService = data => async dispatch => {
    dispatch(serviceChangeOperationStates({ createLoading: true }))
    try {
        const URL = "/service/v1/";

        const startDate = format(new Date(data?.start_date), "yyyy-MM-dd");
        const endDate = format(new Date(data?.end_date), "yyyy-MM-dd");

        const seconds = ":00";

        const successStep = 3;

        const [
            start_date,
            end_date
        ] = [
                new Date(startDate + "T" + data?.start_time + seconds),
                new Date(endDate + "T" + data?.end_time + seconds),
            ];

        const params = {
            services: [{
                descr: data?.descr,
                start_date: start_date.toISOString(),
                end_date: end_date.toISOString(),
            }]

        }

        const {
            // eslint-disable-next-line
            data: { services }
        } = await api.post(URL, qs.stringify(params));

        toast.success(localizedStrings.success.create.service);

        dispatch(serviceCreateSuccess(data));
        dispatch(serviceChangeOperationStates({ createSuccess: true }))
        dispatch(changeStep(successStep));
    } catch (error) {
        dispatch(serviceChangeOperationStates({ createFail: true }));

        toast.error(localizedStrings.error.create.service)
    }
};

const getServiceByDate = ({
    services
}) => {

    const servicePerDate = services.reduce((services, currentService) => {
        const [
            serviceStartYear,
            serviceStartMonth,
        ] = [
                format(new Date(currentService.start_date), "yyyy"),
                format(new Date(currentService.start_date), "MM"),
            ];

        const [
            serviceEndYear,
            serviceEndMonth,
        ] = [
                format(new Date(currentService.end_date), "yyyy"),
                format(new Date(currentService.end_date), "MM"),
            ];

        const [
            serviceYear,
            serviceMonth,
        ] = [
                serviceStartYear + "-" + serviceEndYear,
                serviceStartMonth + "-" + serviceEndMonth,
            ];

        if (!services[serviceYear]) services[serviceYear] = {};

        if (!services[serviceYear][serviceMonth]) services[serviceYear][serviceMonth] = [];

        services[serviceYear][serviceMonth].push(currentService);

        return services

    }, {});

    return servicePerDate;
};

export const cancelException = data => async dispatch => {
    dispatch(serviceChangeOperationStates({ updateLoading: true }));
    try {
        const URL = "logistics/v1/progress/" + data.service_id;

        const cancelledFlag = "00:00:00";

        const payload = {
            progress: [{
                service_id: data.service_id,
                driver_id: data.driver_id,
                vehicle_id: data.vehicle_id,
                place_id: data.place_id,
                departure: cancelledFlag,
                type_departure: "place",
            }]
        };

        await api.put(URL, qs.stringify(payload));

        dispatch(serviceChangeOperationStates({ updateSuccess: true }));

        toast.success(localizedStrings.logisticsServices.serviceCancelled);

    } catch (error) {
        dispatch(serviceChangeOperationStates({ updateFail: true }));
        toast.error(localizedStrings.logisticsServices.errorWhenServiceCancelled)
    }
}

export const setPlacesToService = data => async dispatch => {
    const {
        service,
        places,
        user,
    } = data;

    const servicePlaces = service.places.map(servicePlace => {
        const currentPlace = JSON.parse(JSON.stringify(
            places.find(place => +place.id === +servicePlace.place_id) || {}
        )) || {};

        const placeHasException = servicePlace?.exceptions?.length > 0;

        if (servicePlace.type === 'out') {

            servicePlace.addresses = currentPlace?.addresses?.slice?.().reverse?.() || servicePlace.addresses;

        }

        if (placeHasException) {

            const exceptions = servicePlace.exceptions || [];

            if (+currentPlace.id === +user.id) dispatch(updatePlaceExceptionPerService({
                user,
                exceptions,
                serviceId: service.id
            }))

            const [firstException] = getValidExceptions({
                exceptions,
                placeType: servicePlace?.type,
            });

            if (firstException) currentPlace.addresses.unshift(firstException)

        }

        return {
            ...currentPlace,
            ...servicePlace,
        }
    });

    const newService = {
        ...service,
        places: servicePlaces,
    }
    dispatch(serviceLoadSuccess({
        service: newService,
        servicePlaces
    }));
}

const formatServices = ({
    logistics = [],
}) => {
    return logistics.map(service => ({
        ...service,
        driver_name: service.drivers?.map(driver => driver.name)?.join?.(", "),
        client_name: service.clients?.map(client => client.name)?.join?.(", "),
        vehicle_name: service.vehicles?.map(driver => driver.name)?.join?.(", "),
    }));
};

const formatStatus = (statusPerDay = [], status = 'inactive') => {

    const serviceIsActive = status === 'active';

    const validStatus = statusPerDay.filter(s => {
        
        const progressStatusDate = format(parseISO(s.check_in), "yyyy-MM-dd");
        
        const today = format(new Date(), "yyyy-MM-dd");

        const progressIsToday = progressStatusDate === today;
        return progressIsToday
    });

    const statusTypes = {
        in_progress: () => serviceIsActive && validStatus.some(s => s.check_in && !s.check_out),
        finished: () => serviceIsActive && validStatus.some(s => s.check_in && s.check_out),
        inactive: () => !serviceIsActive && status === 'inactive',
    }

    const newStatus = Object.entries(statusTypes)
        .map(statusType => {
            const [key, value] = statusType;

            const hasFoundStatus = value();

            if (hasFoundStatus) return key;

            return false;
        })
        .filter(Boolean)
        .shift();

    return newStatus || 'not_started';
}

const filterByActive = service => service?.status === 'active';

export const loadServices = data => async dispatch => {
    dispatch(serviceChangeOperationStates({ loadLoading: true }));
    try {
        const params = [];
        const filters = {
            limit: val => val && params.push("limit=" + val),
            offset: (val = 0) => params.push("offset=" + val),
            organization_id: val => val && params.push("organization_id=" + val),
            search_term: val => val && params.push("search_term=" + val),
            service_id: val => val && params.push("service_id=" + val),
            place_ids: val => val && params.push("place_ids=" + val),
        }
        Object.keys(data).forEach(filter => filters?.[filter]?.(data?.[filter]));

        const URL = "/logistics/v1/?" + params.join("&");

        const {
            data: { logistics = [], total }
        } = await api.get(URL);

        const filteredByStatus = logistics?.filter?.(filterByActive) || [];

        const formattedWithNames = formatServices({
            logistics: filteredByStatus,
        });

        dispatch(serviceLoadSuccess({
            services: formattedWithNames,
            total,
            serviceByDate: getServiceByDate({
                services: formattedWithNames,
            })
        }));
        dispatch(serviceChangeOperationStates({ loadSuccess: true }));
    } catch (error) {
        console.log(error);
        dispatch(serviceChangeOperationStates({ loadFail: true }));
    }
};


export const loadOneService = data => async dispatch => {
    dispatch(serviceChangeOperationStates({ loadLoading: true }));
    try {
        const URL = "/logistics/v1/" + data?.id

        const {
            data: { logistic }
        } = await api.get(URL);

        const [formattedWithNames] = formatServices({ logistics: [logistic] });

        const serviceProgress = logistic?.progress || [];

        const serviceWithFormattedStatus = formatStatus(formattedWithNames?.progress_status, formattedWithNames?.status);

        const newService = {
            ...formattedWithNames,
            status: serviceWithFormattedStatus
        }

        dispatch(serviceLoadSuccess({
            service: newService,
            serviceProgress,
        }));
        dispatch(serviceChangeOperationStates({ loadSuccess: true }));
    } catch (error) {
        console.log(error);
        dispatch(serviceChangeOperationStates({ loadFail: true }));
    }
};


export const updateServiceEvaluation = ({
    rating,
    observation,
    place_id,
    vehicle_id,
    driver_id,
    service_id,
}) => async dispatch => {
    dispatch(serviceChangeOperationStates({ updateLoading: true }));
    try {
        const URL = "/logistics/v1/rating/" + service_id

        const ratingPayload = {
            rating: rating,
            observation: observation,
            place_id: place_id,
            vehicle_id: vehicle_id,
            driver_id: driver_id,
            service_id: service_id,
        }

        await api.put(URL, qs.stringify({ rating: ratingPayload }));

        dispatch(serviceChangeOperationStates({ updateSuccess: true }));
    } catch (error) {
        console.log(error);
        dispatch(serviceChangeOperationStates({ updateFail: true }));
    }
};


