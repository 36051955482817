import React, { memo } from 'react';
import { Text } from '../../../components'
import { EmptyStateWrapper } from './style'
import { localizedStrings } from 'constants/localizedStrings';

function EmptyStateNotification({
    context,
    ...options
}) {
    return (
        <EmptyStateWrapper {...options.containerOptions}>
            <Text
                fontSize="29px"
                color="#767676"
                fontWeigth="500"
                lineHeight="44px"
                padding="0 23px"
                textAlign="center"
                {...options.subtitleOptions}
            >
                {localizedStrings.notifications.noNotifications}
            </Text>
        </EmptyStateWrapper >
    );
}
export default memo(EmptyStateNotification)