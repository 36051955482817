
export const SET_BEST_ROUTE_VEHICLE = "set_best_route_vehicle";
export const SET_BEST_ROUTE_SERVICE = "set_best_route_service";
export const SET_BEST_ROUTE = "set_best_route";

const INITIAL_STATE = {
    vehicleRoute: {
        coordinates: [],
        start_location: [],
        end_location: [],
        waypoint_order: [],
        legs: [],
        duration: 0,
        distance: 0,
    },
    serviceRoute: {
        coordinates: [],
        start_location: [],
        end_location: [],
        waypoint_order: [],
        legs: [],
        duration: 0,
        distance: 0,
    },
    routes: {
        coordinates: [],
        start_location: [],
        end_location: [],
        waypoint_order: [],
        legs: [],
        duration: 0,
        distance: 0,
    },
};


export default function map(state = INITIAL_STATE, action) {

    const actionTypes = {
        set_best_route() {
            return {
                ...state,
                routes: {
                    ...state.routes,
                    coordinates: action.payload.coordinates || [],
                    start_location: action.payload.start_location || {},
                    end_location: action.payload.end_location || {},
                    waypoint_order: action.payload.waypoint_order || [],
                    legs: action.payload.legs || [],
                },
            }
        },
        set_best_route_vehicle() {
            return {
                ...state,
                vehicleRoute: {
                    ...state.vehicleRoute,
                    coordinates: action.payload.coordinates || [],
                    start_location: action.payload.start_location || {},
                    end_location: action.payload.end_location || {},
                    waypoint_order: action.payload.waypoint_order || [],
                    legs: action.payload.legs || [],
                    distance: action.payload.distance || [],
                    duration: action.payload.duration || [],
                },
            }
        },
        set_best_route_service() {
            return {
                ...state,
                serviceRoute: {
                    ...state.serviceRoute,
                    coordinates: action.payload.coordinates || [],
                    start_location: action.payload.start_location || {},
                    end_location: action.payload.end_location || {},
                    waypoint_order: action.payload.waypoint_order || [],
                    legs: action.payload.legs || [],
                    duration: action.payload.duration || [],
                    distance: action.payload.distance || [],
                },
            }
        },
    }

    if (actionTypes[action.type]) return actionTypes[action.type]();
    return state
}
