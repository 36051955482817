import LocalizedStrings from 'react-localization';
import login from './login';
import masks from './masks';
import errors from './errors';
import logisticsServices from './logisticsServices';
import notifications from './notifications';
import calendar from './calendar';

const localizedStrings = new LocalizedStrings({
  pt: {
    login,
    masks,
    errors,
    logisticsServices,
    notifications,
    calendar,
  }
}
);
const [userLang] = (navigator.language || navigator.userLanguage).split("-");
export { localizedStrings, userLang };
