import React from 'react';
import { VehiclePinDefault, VehiclePinDiv, ArrowStyle, VehicleName, VehiclePinWrapper } from './style';
import { Icon, Text } from 'components';

export default function VehiclePin({ text, hasText, icon, iconColor = "#000", showVehicleName = false, optionsWrapper = {} ,optionsIcons = {}, optionsArrow = {} ,...options }) {
    return (
        <VehiclePinWrapper onClick={options?.onClick} {... optionsWrapper}>
            {
                showVehicleName &&
                <VehicleName>
                    <Text color={"#fff"}>
                        {options.name}
                    </Text>
                </VehicleName>
            }
            <VehiclePinDiv>
                <VehiclePinDefault iconColor={iconColor} {...options}>
                    <ArrowStyle {... optionsArrow}/>
                    {
                        icon &&
                        <Icon icon={icon} width={"43px"} height={"38px"} marginLeft={"10px"} color={iconColor} {... optionsIcons}/>
                    }
                </VehiclePinDefault>
                {
                    hasText &&
                    <Text>{text}</Text>
                }
            </VehiclePinDiv>
        </VehiclePinWrapper>
    );
}