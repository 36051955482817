import {
  VEHICLE_LOAD_SUCCESS,
  VEHICLE_CHANGE_OPERATION_STATES,
} from "./reducer";
import { api, mapApi } from "services/api";
import { differenceInMinutes, format, formatISO } from "date-fns";
import {
  vehicleOn,
  vehicleOff,
  noSignal,
  noSignal24,
  noModule
} from 'constants/environment'
import { localizedStrings } from "constants/localizedStrings";

function fetchLoadSuccess({ ...states }) {
  return {
    type: VEHICLE_LOAD_SUCCESS,
    payload: {
      ...states
    }
  };
}

function vehicleChangeOperationStates({
  loadLoading = false,
  loadSuccess = false,
  loadFail = false,
  createLoading = false,
  createSuccess = false,
  createFail = false,
  editLoading = false,
  editSuccess = false,
  editFail = false
}) {
  return {
    type: VEHICLE_CHANGE_OPERATION_STATES,
    payload: {
      loadLoading,
      loadSuccess,
      loadFail,
      createLoading,
      createSuccess,
      createFail,
      editLoading,
      editSuccess,
      editFail,
    }
  };
}

const loadVehicles = data => async (dispatch) => {
  dispatch(vehicleChangeOperationStates({ loadLoading: true, }));
  try {
    const params = [];
    const filters = {
      organization_id: val => val && params.push("organization_id=" + val),
      vehicle_id: val => val && params.push("vehicle_id=" + val),
      limit: val => val && params.push("limit=" + val),
      offset: (val = 0) => params.push("offset=" + val),
      search_term: val => val && params.push("search_term=" + val),
      status: val => val && params.push("status=" + val),
    }
    Object.keys(data).forEach(filter => filters?.[filter]?.(data?.[filter] ?? false))

    const URL = "/vehicle/v1?" + params.join("&");

    const {
      data: { vehicles, total }
    } = await api.get(URL);

    dispatch(fetchLoadSuccess({ vehicles, total }));
    dispatch(vehicleChangeOperationStates({ loadSuccess: true, }));
  } catch (error) {
    dispatch(vehicleChangeOperationStates({ loadFail: true }));
  }
};

const getSignalType = ({
  timestamp,
  ignition,
  speed,
}) => {
  if (!ignition) return vehicleOff;

  const signalDiffInMinutes = differenceInMinutes(new Date(), new Date(timestamp));

  const oneDay = 24 * 60;
  const tenMinutes = 10;

  if (signalDiffInMinutes >= oneDay) return noSignal24;

  if (signalDiffInMinutes >= tenMinutes && speed < 10) return noSignal;

  return vehicleOn;
}

const orderByStatus = (a, b) => {

  if (a?.status > b?.status) return 1;

  if (a?.status < b?.status) return -1;

  return 0;
}

const loadMapVehicles = data => async (dispatch) => {
  dispatch(vehicleChangeOperationStates({ loadLoading: true, }));
  try {
    const params = [];

    const filters = {
      vehicle_id: val => val && params.push("vehicle_id=" + val),
      limit: (val = true) => params.push("limit=" + !!val),
    }
    Object.keys(data).forEach(filter => filters?.[filter]?.(data?.[filter] ?? false));

    const URL = "/api/v1/last-points?" + params.join("&");

    const {
      data: { last_points: pointsHistory }
    } = await mapApi.get(URL);
    const statusSummary = {
      [vehicleOn]: 0,
      [vehicleOff]: 0,
      [noSignal]: 0,
      [noSignal24]: 0,
      [noModule]: 0,
    }

    const lastPoints = [pointsHistory]
      .map?.(vehicleObj => {

        const {
          last_positions: rawLastPoints
        } = vehicleObj
        
        const last_positions = [rawLastPoints].flat()

        const has_last_postions = last_positions.length > 0;

        if (has_last_postions === false) {
          statusSummary[noModule] += 1;
          return {
            driver: vehicleObj.driver || {},
            vehicle: vehicleObj.vehicle,
            status: noModule
          }
        }

        const lastPositions = last_positions.slice()?.pop();

        const { timestamp, speed, ignition } = lastPositions;

        if (timestamp === undefined || timestamp === null) return null;

        const [timeZoneHour] = formatISO(new Date(timestamp), { representation: 'time' }).split("-");
        const [
          date,
          hour
        ] = [
            format(new Date(timestamp), localizedStrings.masks.dateMask),
            timeZoneHour,
          ];
        const status = getSignalType({
          timestamp,
          ignition,
          speed,
        });
        statusSummary[status] += 1;

        return {
          ...lastPositions,
          lat: lastPositions.lat,
          lng: lastPositions.lng,
          date,
          hour,
          lastRegister: new Date(timestamp),
          status,
          driver: vehicleObj.driver,
          vehicle: vehicleObj.vehicle,
        };
      })
      .filter?.(vehicleObj => vehicleObj)
      .sort?.(orderByStatus) || [];

    dispatch(fetchLoadSuccess({ pointsHistory, lastPoints, statusSummary }));
    dispatch(vehicleChangeOperationStates({ loadSuccess: true, }));
  } catch (error) {
    console.log(error);
    dispatch(vehicleChangeOperationStates({ loadFail: true }));
  }
};

export {
  loadVehicles,
  vehicleChangeOperationStates,
  loadMapVehicles,
}