import styled from "styled-components";

export const TitleWrapper = styled.div(props => ({
    display: "flex",
    flex: "1",
    justifyContent: "center",
    ["@media" + props.theme.device.laptopM]: {
        justifyContent: "center",
    },
}));
