import React, { useEffect, useState } from 'react'
import { Icon, Text } from 'components'
import { localizedStrings } from 'constants/localizedStrings'
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from 'react-stars'
import {
    EvaluationContainer,
    EvaluationFormWrapper,
    EvaluationArrival,
    EvaluationRatingText,
    ButtonWrapper
} from './style';
import { TextArea } from 'components/inputs';
import { Button } from 'components/buttons';
import { getUrlParam } from 'utils/params';
import { updateServiceEvaluation } from 'store/modules';
const maxLengthObservation = 250;
export default function EvaluationForm() {

    const {
        service,
        updateLoading
    } = useSelector(state => state.services);
    const {
        user: {
            id
        }
    } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const [evaluation, setEvaluation] = useState({
        rating: 0,
        observation: "",
        place_id: 0,
        vehicle_id: 0,
        driver_id: 0,
        service_id: 0,
    });

    useEffect(() => {
        const hasService = !!service?.id;

        if (hasService) {
            const urlParams = {
                rating: getUrlParam("rating"),
                observation: getUrlParam("observation") || "",
            };

            try {
                const ratingExceededLimit = urlParams.rating > 5 || urlParams.rating < 0;

                if (ratingExceededLimit) urlParams.rating = 0;

                urlParams.observation = urlParams.observation.slice(0, maxLengthObservation);

            } catch (error) {
                urlParams.rating = 0;
                urlParams.observation = "";
            }

            setEvaluation({
                ...evaluation,
                ...urlParams,
                place_id: id,
                vehicle_id: service.vehicles.slice().shift().vehicle_id,
                driver_id: service.drivers.slice().shift().driver_id,
                service_id: service?.id,
            });
        }
        // eslint-disable-next-line
    }, [service]);

    const onChangeInput = (field, value) => {
        evaluation[field] = value;
        setEvaluation({ ...evaluation });
    }

    const onSubmit = () => {
        dispatch(
            updateServiceEvaluation(evaluation || {})
        )
    }

    return (
        <EvaluationFormWrapper>
            <EvaluationContainer>
                <EvaluationArrival >
                    <Icon
                        icon="car"
                        width="40px"
                        heigth="40px"
                        divProps={{
                            padding: "20px 30px 20px 20px",
                            border: "1px solid #EAEAEA",
                            borderRadius: "8px"
                        }}
                        color="#3B399250"
                    />
                </EvaluationArrival>
                <EvaluationArrival>
                    <Text
                        color="#1A237A"
                        fontSize="22px"
                        fontWeight="500"
                        textAlign="center"
                        padding="10px 30px"
                        lineHeight="29px"
                    >
                        {localizedStrings.logisticsServices.arrival}
                    </Text>
                </EvaluationArrival>
            </EvaluationContainer>
            {
                service?.id &&
                <EvaluationContainer >
                    <div>
                        <Text
                            color="#3B3992"
                            fontSize="16px"
                            fontWeight="500"
                            textAlign="center"
                            paddingBottom="20px"
                        >
                            {localizedStrings.logisticsServices.questionHowWasYourTrip}
                        </Text>
                    </div>
                    <div>
                        <ReactStars
                            count={5}
                            onChange={(val) => onChangeInput("rating", val)}
                            size={55}
                            half={false}
                            className={"evaluation-stars"}
                            color1={"#AAAAAA"}
                            color2={'#FAA628'}
                            value={evaluation.rating}
                        />
                        <EvaluationRatingText>
                            <Text
                                color="#AAAAAA"
                                fontSize="12px"
                                textAlign="center"
                                flex="1"
                            >
                                {localizedStrings.logisticsServices.rating.veryBad}
                            </Text>
                            <Text
                                color="#AAAAAA"
                                fontSize="12px"
                                textAlign="center"
                                flex="1"
                            >
                                {localizedStrings.logisticsServices.rating.bad}
                            </Text>
                            <Text
                                color="#AAAAAA"
                                fontSize="12px"
                                textAlign="center"
                                flex="1"
                            >
                                {localizedStrings.logisticsServices.rating.ok}
                            </Text>
                            <Text
                                color="#AAAAAA"
                                fontSize="12px"
                                textAlign="center"
                                flex="1"
                            >
                                {localizedStrings.logisticsServices.rating.good}
                            </Text>
                            <Text
                                color="#AAAAAA"
                                fontSize="12px"
                                textAlign="center"
                                flex="1"
                            >
                                {localizedStrings.logisticsServices.rating.veryGood}
                            </Text>
                        </EvaluationRatingText>
                    </div>
                </EvaluationContainer>
            }
            {
                evaluation.rating > 0 &&
                <>
                    <EvaluationContainer child={{
                        padding: "0 18px"
                    }}>
                        <div>
                            <Text
                                color="#3B3992"
                                fontSize="16px"
                                fontWeight="500"
                                textAlign="center"
                            >
                                {localizedStrings.logisticsServices.questionObservation}
                            </Text>
                        </div>
                        <TextArea
                            placeholder={localizedStrings.logisticsServices.placeholderObservation}
                            maxLength={maxLengthObservation}
                            name={"observation"}
                            defaultValue={evaluation.observation}
                            onChange={onChangeInput}
                        />

                        <ButtonWrapper>
                            <Button
                                hasLoad
                                backgroundColor={updateLoading ? false : "#192379"}
                                color="#fff"
                                width="100%"
                                margin="15px 0 0 0"
                                height="36px"
                                minWidth="41px"
                                flex="1"
                                padding="0"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onSubmit();
                                }}
                                title={localizedStrings.logisticsServices.sendEvaluation}
                                textConfig={{
                                    fontWeight: '500',
                                    fontSize: "13px",
                                    color: "#fff"
                                }}
                                loading={updateLoading}
                                disabled={updateLoading}
                            />
                        </ButtonWrapper>
                    </EvaluationContainer>
                </>
            }
        </EvaluationFormWrapper>
    )
}
