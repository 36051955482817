import React, { useEffect } from "react";
import {
    Header,
    UserNotifications
} from "containers";
import { useDispatch, useSelector } from "react-redux";
import {
    loadNotifications,
} from "store/modules";
import "react-virtualized/styles.css";

import { MAX_LENGHT } from "constants/environment";

export default function LogisticsServicesNotifications({ history, title, subtitle, icon }) {

    const dispatch = useDispatch();

    const {
        user: {
            organization_id,
        }
    } = useSelector(state => state.auth);


    const loadOrganizationNotifications = () => {
        dispatch(
            loadNotifications({
                limit: MAX_LENGHT,
                offset: 0,
                organization_id,
            })
        );
    };

    useEffect(() => {

        loadOrganizationNotifications();
        // eslint-disable-next-line
    }, []);


    return (
        <Header
            type="notification"
            contentStyle={{
                background: "#F8F8FB"
            }}
        >

            <UserNotifications />

        </Header>
    );
}
