import {
    NOTIFICATION_CHANGE_OPERATION_STATES,
    NOTIFICATION_LOAD_SUCCESS,
    NOTIFICATION_CLEAR_SUCCESS,
} from './reducer';

// import { api } from "services/api";
import { format } from 'date-fns';

export function notificationLoadSuccess({
    notifications,
    total,
    hasNotifications
}) {
    return {
        type: NOTIFICATION_LOAD_SUCCESS,
        payload: {
            notifications,
            total,
            hasNotifications,
        }
    };
}
export function notificationClearSuccess() {
    return {
        type: NOTIFICATION_CLEAR_SUCCESS,
    };
}


export function notificationChangeOperationStates({
    loadLoading = false,
    loadSuccess = false,
    loadFail = false,
    updateLoading = false,
    updateSuccess = false,
    updateFail = false,
}) {
    return {
        type: NOTIFICATION_CHANGE_OPERATION_STATES,
        payload: {
            loadLoading,
            loadSuccess,
            loadFail,
            updateLoading,
            updateSuccess,
            updateFail,
        }
    };
}


export const updateNotification = data => async dispatch => {
    dispatch(notificationChangeOperationStates({ updateLoading: true }));
    try {
        if (!data?.id) throw new Error("Notification Doesnt exists");

        // const URL = "/notification/" + data.id;

        // await api.put(URL);

        dispatch(notificationClearSuccess());
        dispatch(notificationChangeOperationStates({ updateSuccess: true }));
    } catch (error) {
        dispatch(notificationChangeOperationStates({ updateFail: true }));
    }
}

const addDateFlagToNotifications = ({
    notifications = []
}) => {
    const dates = {};

    const dateFlaggedNotifications = notifications.map(notification => {

        const notificationDate = format(new Date(notification.date), "yyyy-MM-dd");

        const hasOthersNotificationsInCurrentDate = dates[notificationDate] !== undefined;

        if (hasOthersNotificationsInCurrentDate) {
            dates[notificationDate] += 1;
            return notification;
        }

        dates[notificationDate] = 0;

        return {
            ...notification,
            isFirstNotificationOfDay: true,
        }

    });
    return dateFlaggedNotifications;
};

const sortByNew = (a, b) => {
    try {
        const hasNewestDate = new Date(a.date) < new Date(b.date);

        if (hasNewestDate) return 1;

        return -1

    } catch (error) {
        return 0
    }
}

export const loadNotifications = data => async dispatch => {
    dispatch(notificationChangeOperationStates({ loadLoading: true }));
    try {
        const params = [];
        const filters = {
            limit: val => val && params.push("limit=" + val),
            offset: (val = 0) => params.push("offset=" + val),
            organization_id: val => val && params.push("organization_id=" + val),
            search_term: val => val && params.push("search_term=" + val),
            status: val => val && params.push("status=" + val),
            notification_id: val => val && params.push("notification_id=" + val),
        }
        Object.keys(data).forEach(filter => filters?.[filter]?.(data?.[filter]));

        // const URL = "/notification/v1/?" + params.join("&");

        // const {
        //     data: { notifications, total }
        // } = await api.get(URL);

        // Example payload:
        const notifications = [
            {
                id: 1,
                date: format(new Date(2020, 4, 16, 12, 0, 0), "yyyy-MM-dd'T'HH:mm:ss"),
                title: "Aviso sobre o feriado do dia 21/04 - terça feira",
                description: "Devido ao veriado na proxima terça feira não teremos transporte de passageiro para o seu contrato. Para maiores informações entre em contato com o nosso SAC",
                status: 1,
            },
            {
                id: 1,
                date: format(new Date(2020, 4, 15, 12, 0,), "yyyy-MM-dd'T'HH:mm:ss"),
                title: "Aviso sobre o feriado do dia 21/04 - terça feira",
                description: "Devido ao veriado na proxima terça feira não teremos transporte de passageiro para o seu contrato. Para maiores informações entre em contato com o nosso SAC",
                status: 0,
            },
            {
                id: 1,
                date: format(new Date(2020, 4, 16, 12, 0, 0), "yyyy-MM-dd'T'HH:mm:ss"),
                title: "Aviso sobre o feriado do dia 21/04 - terça feira",
                description: "Devido ao veriado na proxima terça feira não teremos transporte de passageiro para o seu contrato. Para maiores informações entre em contato com o nosso SAC",
                status: 1,
            },
            {
                id: 1,
                date: format(new Date(2020, 4, 14, 12, 0,), "yyyy-MM-dd'T'HH:mm:ss"),
                title: "Aviso sobre o feriado do dia 21/04 - terça feira",
                description: "Devido ao veriado na proxima terça feira não teremos transporte de passageiro para o seu contrato. Para maiores informações entre em contato com o nosso SAC",
                status: 0,
            },
            {
                id: 1,
                date: format(new Date(2020, 4, 15, 12, 0,), "yyyy-MM-dd'T'HH:mm:ss"),
                title: "Troca de motorista",
                description: "O motorista Sergio Cortela foi acometido pelo Covid 19 e não poderá fazer o transporte . O motorista substituido será o Matheus Lima",
                status: 0,
            },
            {
                id: 1,
                date: format(new Date(2020, 4, 14, 12, 0,), "yyyy-MM-dd'T'HH:mm:ss"),
                title: "Troca de motorista",
                description: "O motorista Sergio Cortela foi acometido pelo Covid 19 e não poderá fazer o transporte . O motorista substituido será o Matheus Lima",
                status: 0,
            },
        ];


        const activeNotificationsSortedByNew = notifications
            .sort(sortByNew)

        const dateFlaggedNotifications = addDateFlagToNotifications({ notifications: activeNotificationsSortedByNew });

        const total = dateFlaggedNotifications?.length || 0;

        const hasNotifications = total > 0;

        dispatch(notificationLoadSuccess({
            notifications: dateFlaggedNotifications,
            total,
            hasNotifications,
        }));
        dispatch(notificationChangeOperationStates({ loadSuccess: true }));
    } catch (error) {
        console.log(error);
        dispatch(notificationChangeOperationStates({ loadFail: true }));
    }
};


