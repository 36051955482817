import styled from 'styled-components'
const cardStyle = styled.div(props => ({
    display: "flex",
    margin: "25px 0 25px 0",
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    boxShadow: "0px 0px 13px rgba(49, 48, 99, 0.08)",
    borderRadius: "4px",
    flexDirection: 'column',
    ["@media" + props.theme.device.laptopM]: {
        margin: "0",
    },
    ...props
}))
const cardTitleStyle = styled.div(props => ({
    display: "flex",
    padding: "16px",
    borderBottom: "solid 1px",
    borderColor: "rgba(0, 0, 0, 0.07)",
    alignItems: "center",
    "& p": props.shrinkable && {
        flex: "1"
    },
    "& > div": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex",
        alignItems: "center",
        maxHeight: "25px"
    },
    ...props
}))
const cardContentStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: "0 0 20px 20px",
    ["@media" + props.theme.device.laptopM]: {
        borderRadius: "0",
        "& >div>div>div.row": {
            margin: "0px",
        },
        "& .hide-mobile": {
            display: "none",
        },
    },
    ...props,
}))
const placeholderAnimation = styled.div(props => ({
    animationDuration: "4s",
    animationFillMode: "forwards",
    animationIterationCount: "infinite",
    animationName: "placeHolderShimmer",
    animationTimingFunction: "linear",
    background: "linear-gradient(to right, #E5E5E5 10%,#f0f0f0 18%,#E5E5E5 33%)",
    height: "433px",
}))

export {
    cardStyle as CardStyle,
    cardTitleStyle as CardTitleStyle,
    placeholderAnimation as PlaceholderAnimation,
    cardContentStyle as CardContentStyle,
}