import styled from 'styled-components';

export const HeaderStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 10px 0",
    "&>div": {
        flex: "1",
    }
}));
export const WeekNames = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    "&>*": {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
}));