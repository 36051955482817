import React, { useEffect, useState } from "react";
import {
    Header,
    UserEvaluation,
    EvaluationThankYou
} from "containers";
import { useDispatch, useSelector } from "react-redux";
import {
    loadOneService, loadPlaces, loadVehicles
} from "store/modules";
import { MAX_LENGHT } from "constants/environment";
import { Modal } from "components";
import { LOGISTICS_SERVICES_MANAGE_PATH } from "constants/paths";
import { toast } from "react-toastify";
import { localizedStrings } from "constants/localizedStrings";
import { verifyIfUserHasCompletedProgress } from "utils/services";

export default function LogisticsServicesEvaluation({ history, match }) {

    const dispatch = useDispatch();

    const [thankYouModalVisible, setThankYouModalVisible] = useState(false);

    const {
        user: {
            organization_id,
            userId,
        }
    } = useSelector(state => state.auth);

    const {
        updateSuccess,
        loadFail,
        loadSuccess,
        service
    } = useSelector(state => state.services)

    const loadService = () => {
        dispatch(
            loadOneService({
                id: match?.params?.id
            })
        )
    }
    const loadOrganizationPlaces = () => {
        dispatch(
            loadPlaces({
                limit: MAX_LENGHT,
                organization_id
            })
        )
    }

    const loadOrganizationVehicles = () => {
        dispatch(
            loadVehicles({
                limit: MAX_LENGHT,
                organization_id
            })
        )
    }

    useEffect(() => {

        loadOrganizationPlaces();
        loadOrganizationVehicles();
        loadService();

        // eslint-disable-next-line
    }, [match?.params?.id])

    useEffect(() => {

        setThankYouModalVisible(updateSuccess);

    }, [updateSuccess]);

    useEffect(() => {

        if (loadFail) {
            toast.error(localizedStrings.logisticsServices.serviceUnavailable);
            history.push(LOGISTICS_SERVICES_MANAGE_PATH);
        }

        if (loadSuccess) {

            const evaluateStatus = {
                in_progress: true,
                paused: true,
                finished: true,
            };

            const userHasArrived = verifyIfUserHasCompletedProgress({
                userId,
                service,
            });

            const serviceIsEvaluable = evaluateStatus[service.status] === true;

            const userCanEvaluateThisService = (
                userHasArrived &&
                serviceIsEvaluable
            );

            if (!userCanEvaluateThisService) history.push(LOGISTICS_SERVICES_MANAGE_PATH);

        }
        // eslint-disable-next-line
    }, [loadFail, loadSuccess]);

    return (
        <Header
            type="evaluation"
            contentStyle={{
                background: "#F8F8FB"
            }}
        >
            <Modal
                header={<EvaluationThankYou />}
                open={thankYouModalVisible}
                setOpen={setThankYouModalVisible}
            />

            <UserEvaluation />

        </Header>
    );
}
