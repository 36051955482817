import React, { useEffect, useMemo, useState } from 'react'
import { Icon, Text } from 'components'
import {
    CurrentPlaceDetailsStyle,
    StopHoursStyle,
    DetailsDiv,
    Status,
} from './style'
import {
    useDispatch,
    useSelector
} from 'react-redux';
import { localizedStrings } from 'constants/localizedStrings';
import { addSeconds, format, } from 'date-fns';
import nextDay from 'date-fns/nextDay'
import { getBestRoute } from 'store/modules';
import { ptBR } from 'date-fns/esm/locale';
import {
    LOGISTICS_SERVICES,
    ROUTE
} from 'constants/paths'

export default function CurrentPlaceDetails() {

    const {
        service,
        servicePlaces,
    } = useSelector(state => state.services);

    const {
        routes
    } = useSelector(state => state.map);

    const {
        user: {
            name: userName,
            id: currentUserId
        } = {}
    } = useSelector(state => state.auth);

// eslint-disable-next-line
    const [boardingAndLandingTime, setBoardingAndLandingTime] = useState({});

    const [departure, setDeparture] = useState({});

    const dispatch = useDispatch();

    const searchBestRoute = () => {
        const serviceHasPlaces = Array.isArray(servicePlaces) && servicePlaces.length > 0;
        if (!serviceHasPlaces) return;

        const placesFromService = servicePlaces.slice();

        const [
            firstPlace,
            lastPlace
        ] = [
                placesFromService.shift(),
                placesFromService.pop(),
            ];

        // if the place has exceptions, the exception will be the first item in the addresses array
        const [originAddress = {}] = firstPlace.addresses;
        const [destinationAddress = {}] = lastPlace.addresses;

        const origin = {
            lat: originAddress.lat || 0,
            lng: originAddress.lng || 0,
        }

        const destination = {
            lat: destinationAddress.lat || 0,
            lng: destinationAddress.lng || 0,
        }

        const coordinates = servicePlaces
            .map(place => {
                const address = place?.addresses?.slice?.()?.pop?.() || {};
                return [
                    address.lat,
                    address.lng
                ].join(",")
            });

        const waypoints = [
            ...coordinates
        ].join("|");

        dispatch(getBestRoute({ latLng: { origin, destination }, waypoints }));
    };

    const showDepartureAddress = () => {
        const destinationAddress = servicePlaces.slice().pop().addresses || [];

        const passengerOutAddress = servicePlaces.find(p => p.id === currentUserId && p.type === 'out')?.addresses || [];

        const departurePlace = {
            addresses: destinationAddress || [],
        };

        const hasPassengerOutAddress = passengerOutAddress?.length > 0;

        if (hasPassengerOutAddress) departurePlace.addresses = passengerOutAddress

        const [departureAddress] = departurePlace.addresses || [];

        const fullDepartureAddress = [
            departureAddress?.address1,
            departureAddress?.number,
            departureAddress?.city,
            departureAddress?.state,
        ].filter(Boolean).join(", ");

        setDeparture({
            ...departureAddress,
            fullAddress: fullDepartureAddress,
        });
    };

    const showDepartureTime = () => {
        const { places } = service;
        const hasPlaces = Array.isArray(places) && places.length > 0;
        if (!hasPlaces) return;

        const departurePlace = places.find(place => place.type === 'in' && !!place.departure);
        const arrivalPlace = places.find(place => place.type === 'out' && !!place.departure);

        const formattedDepartureTime = departurePlace?.departure.substring(0, 5);
        const formattedArrivalTime = arrivalPlace?.departure.substring(0, 5);

        const departureAndArriveTime = {
            departureTime: formattedDepartureTime || '',
            arrivalTime: formattedArrivalTime || '',
        };
        setBoardingAndLandingTime(departureAndArriveTime);
    };

    const currentPlaceStatus = useMemo(()=> {
        if (!service.id || !servicePlaces.length > 0) return null;

        const notFinishedPOI = servicePlaces.reduce((acc, actual, index) => {
            const startingOrFinishingPoint = index === 0 || index === servicePlaces.length - 1;
            if(startingOrFinishingPoint) return acc;
            
            const donePlace = service.progress.some((progress)=> progress.place_id === actual.place_id)

            if(donePlace) return acc;

            return acc.concat(actual)

        }, [])

        const currentPlaceCancelled = service.progress.some((actual) => {
            const cancelled = actual.departure === "00:00:00.000000";
            const isCurrent  = actual.place_id === currentUserId;
            return cancelled && isCurrent;
        })

        const [firstPlace] =  notFinishedPOI
        const currentIsNextPlace = firstPlace?.place_id === currentUserId;
        const isFinishedPlace = service.progress.some((actual)=> actual.place_id === currentUserId)

        if(!currentIsNextPlace && !isFinishedPlace) {
            return "paused";
        }

        if(isFinishedPlace && !currentPlaceCancelled) {
            return "finished";
        }

        if(currentIsNextPlace) {
            return "in_progress";
        }

        if(currentPlaceCancelled) {
            return "inactive";
        }   
        
    },[service, servicePlaces])
    
    useEffect(() => {
        const hasWaypointOrder = Array.isArray(routes?.waypoint_order) && routes?.waypoint_order?.length > 0;

        const hasToShowDepartureTime = !!hasWaypointOrder && !!service;

        if(hasToShowDepartureTime) showDepartureTime()
        // eslint-disable-next-line
    }, [routes.waypoint_order, service]);

    useEffect(() => {

        const serviceHasPlaces = servicePlaces?.length > 0;

        if (serviceHasPlaces) {

            searchBestRoute();

            showDepartureAddress();

        }

        // eslint-disable-next-line
    }, [servicePlaces, service]);

    const closestWeekDayDate = useMemo(() => {
        if(!!service?.day_of_weeks) {

            const isToday = service?.day_of_weeks.includes(format(new Date(), "E").toLocaleLowerCase())
            if(!!isToday) return new Date()

            const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
    
            const [closest] = service?.day_of_weeks
            .map(day => nextDay(new Date(), dayOfWeek.indexOf(day)))
            .sort((a,b) => a > b ? 1 : -1 )
    
            return closest;
        }
    }, [service?.day_of_weeks])

    return (
        !service?.id ? null :
            <DetailsDiv>
                {
                    currentPlaceStatus &&
                    <Status status={currentPlaceStatus} />
                }

                <CurrentPlaceDetailsStyle>
                    {
                        service?.places?.length > 0 &&
                        <Text
                            color="#505050"
                            textTransform="uppercase"
                            fontSize="18px"
                            fontWeight="bold"
                        >
                            {userName}
                        </Text>
                    }
                    {
                        closestWeekDayDate &&
                        <>
                            <Text
                                fontSize="14px"
                                color="#505050"
                                padding="16px 0 0"
                            >
                                {localizedStrings.logisticsServices.passengerTransportation}
                            </Text>
                            <Text
                                fontSize="14px"
                                color="#444444"
                                fontWeight="bold"
                                padding="5px 0 0"
                            >

                                    {`${format(new Date(closestWeekDayDate), "dd/MM/yyyy")} 
                                    ${boardingAndLandingTime.departureTime} - `}
                                    <span>
                                        {
                                            format(new Date(closestWeekDayDate), "EEEE", {
                                                locale: ptBR
                                            })
                                        }
                                    </span>
                            </Text>
                        </>
                    }
                    <StopHoursStyle>
                        {
                            departure?.fullAddress &&
                            <div>
                                <Text
                                    color="#505050"
                                    fontSize="14px"
                                >
                                    {localizedStrings.logisticsServices.landing}
                                </Text>
                                <Text
                                    fontSize="14px"
                                    color="#444444"
                                    fontWeight="bold"
                                    padding="5px 0 0"
                                >
                                    {
                                        `${format(new Date(closestWeekDayDate), "dd/MM/yyyy")}
                                        ${boardingAndLandingTime.arrivalTime}`
                                    }
                                </Text>
                                <Text
                                    fontSize="14px"
                                    color="#444444"
                                    fontWeight="bold"
                                    padding="5px 0 0"
                                >
                                    {
                                        departure?.fullAddress
                                    }
                                </Text>
                            </div>
                        }
                        {
                            service?.id &&
                            <Icon
                                icon="location"
                                width="20px"
                                height="20px"
                                divProps={{
                                    background: "#0000",
                                    borderRadius: "8px",
                                    border: "1px solid #1a2565",
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    width: "45px",
                                    height: "45px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 0 0 5px !important",
                                    as: "a",
                                    href: LOGISTICS_SERVICES + "/" + service.id + ROUTE
                                }}
                            />
                        }

                    </StopHoursStyle>
                </CurrentPlaceDetailsStyle>
            </DetailsDiv>
    )
}
