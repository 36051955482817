import { localizedStrings } from "./localizedStrings";



const keys = {
    map: {
        stg: "AIzaSyDGwVQ_fJt2-dPxWwjrO6PkIP86huBqzR8",
        prd: "AIzaSyCLrE_YpU4m_Ay0z8PkRe2fdMlFvVAcGYA",
    },
    geocode: {
        stg: "AIzaSyBuIUojNMimRbSgUqede7C_D-07h6b1Mhw",
        prd: "AIzaSyBs1LKNNFJybc4Lcw_HSla76Y8SsEQBC2E",
    },
    directions: {
        stg: "AIzaSyBuIUojNMimRbSgUqede7C_D-07h6b1Mhw",
        prd: "AIzaSyBs1LKNNFJybc4Lcw_HSla76Y8SsEQBC2E",
    },
};
const urls = {
    api: {
        stg: "https://tmp-cgv-stg-api.contele.io/",
        prd: "https://cgv-prd-api.contele.io/",
    },
    map: {
        stg: "https://tmp-cgv-stg-last-tracking-points-api.contele.io/",
        prd: "https://cgv-prd-last-tracking-points-api.contele.io/",
    },
    proxy: {
        stg: "https://staging-proxy-api-googlemaps.contele.com.br/",
        prd: "https://prd-proxy-api-googlemaps.contele.com.br/",
    },
    passenger: {
        stg: ["cgv-stg-web-passenger-frontend.contele.io"],
        prd: ["passageiro.contelerastreador.com.br", "cgv-prd-web-passenger-frontend.contele.io"],
    },
    system: {
        stg: ["cgv-stg-web-frontend.contele.io"],
        prd: ["app.contelerastreador.com.br", "cgv-prd-web-frontend.contele.io",],
    }
};

export const PRD_HOSTS = {
    "cgv-prd-web-passenger-frontend.contele.io": true,
    "passageiro.contelerastreador.com.br": true,
};

const host = window.location.host;

export const IS_PRD = urls.passenger.prd.includes(host);

const env = IS_PRD ? "prd" : "stg";

export const SYSTEM_URL = urls.system[env].includes(host) ? host : urls.system[env][0];

export const API_URL = urls.api[env];

export const API_URL_PROXY = urls.proxy[env];

export const API_URL_MAP = urls.map[env];

export const API_KEY_MAP = keys.map[env];

export const API_KEY_MAP_GEOCODE = keys.geocode[env];

export const API_KEY_MAP_DIRECTIONS = keys.directions[env];


export const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SUCCESS: 200,
    NOT_FOUND: 404,
};

export const MAX_LENGHT = 100000;
export const PER_PAGE_LENGTHS = ["50", "100", "500"]

export const METER_BETWEEN_VEHICLE_AND_END_LOCATION = 50;

export const [
    vehicleOn,
    vehicleOff,
    noSignal,
    noSignal24,
    noModule
] = [0, 1, 2, 3, 4];

export const vehiclesStatusTypes = {
    [vehicleOn]: {
        text: localizedStrings.logisticsServices.vehicleTurnOn,
        textPlural: localizedStrings.logisticsServices.vehiclesTurnOn,
        color: "#1DC9B7",
    },
    [vehicleOff]: {
        text: localizedStrings.logisticsServices.vehicleTurnOff,
        textPlural: localizedStrings.logisticsServices.vehiclesTurnOff,
        color: "#6C757D",
    },
    [noSignal]: {
        text: localizedStrings.logisticsServices.vehicleNoSignal,
        textPlural: localizedStrings.logisticsServices.vehiclesNoSignal,
        color: "#FFC241",
    },
    [noSignal24]: {
        text: localizedStrings.logisticsServices.vehicleNoSignalForMoreThen24Hours,
        textPlural: localizedStrings.logisticsServices.vehiclesNoSignalForMoreThen24Hours,
        color: "#F87700",
    },
    [noModule]: {
        text: localizedStrings.logisticsServices.vehicleNoModule,
        textPlural: localizedStrings.logisticsServices.vehiclesNoModule,
        color: "#FF2C5E",
    },
}