import React, { useState } from 'react'
import { localizedStrings } from 'constants/localizedStrings';
import { Text } from 'components';
import {
    HeaderStyle,
    WeekNames
} from './style'
const weekDaysTranslated = localizedStrings.calendar.days
export default function Header() {

    const [weekDays] = useState(Object.values(weekDaysTranslated));

    return (
        <HeaderStyle>
            <WeekNames>
                {
                    weekDays.map((dayName, index) => (
                        <div key={index}>
                            <Text
                                textAlign="center"
                                color="#AAAAAA"
                                fontWeight="500"
                                fontSize="14px"
                            >
                                {dayName}
                            </Text>
                        </div>
                    ))
                }
            </WeekNames>
        </HeaderStyle>
    )
}
