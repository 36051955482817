import { SYSTEM_URL } from "./environment"

export const MANAGE = "/gerenciar"
export const NOTIFICATIONS = "/notificacoes"
export const DETAILS = "/detalhes"
export const ROUTE = "/rota"

export const LOGISTICS_SERVICES = "/servicos"
export const CALENDAR = "/calendario"
export const EVALUATION = "/avaliacao"
export const LOGIN = "/login"
export const FORGOT = "/esqueci-a-senha"

//  ------ LOGIN ------  //
export const LOGIN_PATH = LOGIN
export const SYSTEM_LOGIN_FORGOT_PATH = SYSTEM_URL + LOGIN + FORGOT

//  ------ LOGISTICS_SERVICES ------  //
export const LOGISTICS_SERVICES_PATH = LOGISTICS_SERVICES
export const LOGISTICS_SERVICES_NOTIFICATIONS_PATH = LOGISTICS_SERVICES_PATH + NOTIFICATIONS
export const LOGISTICS_SERVICES_MANAGE_PATH = LOGISTICS_SERVICES_PATH + MANAGE
export const LOGISTICS_SERVICES_DETAILS_PATH = LOGISTICS_SERVICES_PATH + DETAILS
export const LOGISTICS_SERVICES_ROUTE_PATH = LOGISTICS_SERVICES_PATH + ROUTE
export const LOGISTICS_SERVICES_CALENDAR_PATH = LOGISTICS_SERVICES_PATH + CALENDAR
export const LOGISTICS_SERVICES_EVALUATION_PATH = LOGISTICS_SERVICES_PATH + EVALUATION
