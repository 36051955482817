
import styled from 'styled-components';
import '../../assets/fonts.css'

export const LinkDefault = styled.a(props => ({
    fontFamily: props.fontFamily || 'Roboto',
    margin: props.margin || '0px',
    fontSize: props.fontSize || '13px',
    lineHeight: props.lineHeight || '18px',
    fontWeight: props.fontWeight || 'normal',
    transition: "all 0.2s ease-out",
    color: "#393791",
    "&:hover": {
        textDecoration: "none",
        ...props.hover
    },
    ...props
}));