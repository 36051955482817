import { combineReducers } from "redux";
import auth from "./auth/reducer";
import services from "./services/reducer";
import organization from "./organization/reducer";
import vehicles from "./vehicles/reducer";
import notifications from "./notifications/reducer";
import map from "./map/reducer";
import places from "./places/reducer";
import calendar from "./calendar/reducer";

const allReducers = combineReducers({
  auth,
  services,
  vehicles,
  organization,
  notifications,
  map,
  places,
  calendar,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    localStorage.clear()
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;