import React, { useMemo } from "react";
import {
    CurrentPlaceDetails,
    DepartureIdentification,
    ScreenLoading,
    VehicleDetails,
} from "components";
import {
    UserServiceWrapper,
} from './style';
import { useSelector } from "react-redux";

export default function ServiceDetails() {

    const {
        loadLoading: loadService,
    } = useSelector(state => state.services);
    const {
        loadLoading: loadVehicle,
    } = useSelector(state => state.vehicles);
    const {
        loadLoading: loadPlaces,
    } = useSelector(state => state.places);

    const isLoading = useMemo(
        () => loadService || loadVehicle || loadPlaces,
        [loadService, loadVehicle, loadPlaces]
    );

    return (
        <UserServiceWrapper>
            {
                isLoading
                    ? <ScreenLoading loading={isLoading} />
                    : <>
                        <DepartureIdentification />
                        <CurrentPlaceDetails />
                        <VehicleDetails />
                    </>
            }

        </UserServiceWrapper>
    )
}
