import React from 'react'
import { Icon, Link, Text } from 'components';
import {
    EvaluationContent,
    EvaluationContainer,
    EvaluationThankWrapper
} from './style';
import { localizedStrings } from 'constants/localizedStrings';
import { LOGISTICS_SERVICES_MANAGE_PATH } from 'constants/paths';

export default function EvaluationThankYou() {
    return (
        <EvaluationThankWrapper>
            <EvaluationContainer padding="18px 0 0 0">
                <EvaluationContent >
                    <Icon
                        icon="circle-check"
                        width="100px"
                        heigth="100px"
                        color="#1DC9B7"
                    />
                </EvaluationContent>
                <EvaluationContent>
                    <Text
                        color="#1A237A"
                        fontSize="22px"
                        fontWeight="500"
                        textAlign="center"
                        padding="10px 30px"
                        lineHeight="29px"
                    >
                        {localizedStrings.logisticsServices.thankYouForEvaluating}
                    </Text>
                </EvaluationContent>
            </EvaluationContainer>
            <EvaluationContainer padding="0 0 18px 0">
                <EvaluationContent >
                    <Text
                        color="#444444"
                        fontSize="18px"
                        fontWeight="500"
                        textAlign="center"
                        padding="10px 30px"
                        lineHeight="29px"
                    >
                        {localizedStrings.logisticsServices.evaluationSendSuccessfully}
                    </Text>
                </EvaluationContent>
                <EvaluationContent width="100%">
                    <Link
                        backgroundColor={"#192379"}
                        color="#fff"
                        width="100%"
                        borderRadius="5px"
                        margin="0 20px"
                        padding="12px"
                        textAlign="center"
                        href={LOGISTICS_SERVICES_MANAGE_PATH}
                    >
                        {localizedStrings.logisticsServices.close}
                    </Link>
                </EvaluationContent>
            </EvaluationContainer>
        </EvaluationThankWrapper>
    )
}
