import {
    PLACE_CHANGE_OPERATION_STATES,
    PLACE_LOAD_SUCCESS,

} from './reducer';

import { api } from "services/api";

export function placeLoadSuccess(places, total) {
    return {
        type: PLACE_LOAD_SUCCESS,
        payload: {
            places,
            total,
        }
    };
}


export function placeChangeOperationStates({
    loadLoading = false,
    loadSuccess = false,
    loadFail = false,

}) {
    return {
        type: PLACE_CHANGE_OPERATION_STATES,
        payload: {
            loadLoading,
            loadSuccess,
            loadFail,

        }
    };
}

export const loadPlaces = data => async dispatch => {
    dispatch(placeChangeOperationStates({ loadLoading: true }));
    try {
        const params = [];
        const filters = {
            limit: val => val && params.push("limit=" + val),
            offset: (val = 0) => params.push("offset=" + val),
            organization_id: val => val && params.push("organization_id=" + val),
            search_term: val => val && params.push("search_term=" + val),
            status: val => val && params.push("status=" + val),
            place_ids: val => val && params.push("place_ids=" + val),
        }
        Object.keys(data).forEach(filter => filters?.[filter]?.(data?.[filter]));

        const URL = "/place/v1/?" + params.join("&");

        const {
            data: { places, total }
        } = await api.get(URL);

        const formattedPlaces = places.map(place => {
            const [bestAddress] = place?.addresses;

            const hasAddress = bestAddress?.address1 && bestAddress?.city && bestAddress?.state;

            const fullAddress = [
                bestAddress?.address1,
                bestAddress?.number,
                bestAddress?.city,
                bestAddress?.state
            ].join(", ");

            return {
                fullAddress: hasAddress && fullAddress,
                ...place,
            }
        })

        dispatch(placeLoadSuccess(formattedPlaces, total));
        dispatch(placeChangeOperationStates({ loadSuccess: true }));
    } catch (error) {
        console.log(error);
        dispatch(placeChangeOperationStates({ loadFail: true }));
    }
};
