export const PLACE_CHANGE_OPERATION_STATES = "place_change_operation_states";

export const PLACE_LOAD_SUCCESS = "place_load_success";


const operationStates = {
  loadLoading: false,
  loadSuccess: false,
  loadFail: false,
  createLoading: false,
  createSuccess: false,
  createFail: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
  updateLoading: false,
}

const INITIAL_STATE = {
  places: [],
  total: 0,
  selectors: {},
  searchedAddress: {},
  ...operationStates,
};

export default function places(state = INITIAL_STATE, action) {
  const actionTypes = {
    place_change_operation_states() {
      return {
        ...state,
        ...action.payload
      };
    },
    place_load_success() {
      return {
        ...state,
        places: action.payload.places,
        total: action.payload.total,
      };
    },
  };

  if (actionTypes[action.type]) return actionTypes[action.type]();
  return state;
}
