import React, { useEffect } from "react";
import {
    Header,
    UserCalendar
} from "containers";
import { useDispatch, useSelector } from "react-redux";
import {
    loadServices,
} from "store/modules";
import "react-virtualized/styles.css";

import { MAX_LENGHT } from "constants/environment";

export default function LogisticsServicesCalendar({ match }) {

    const dispatch = useDispatch();

    const {
        user: {
            id
        }
    } = useSelector(state => state.auth);


    const loadOrganizationServices = () => {
        dispatch(
            loadServices({
                limit: MAX_LENGHT,
                offset: 0,
                place_ids: [id],
            })
        );
    };
    useEffect(() => {

        loadOrganizationServices();
        // eslint-disable-next-line
    }, []);


    return (
        <Header
            type={"calendar"}
            contentStyle={{
                background: "#FFF",
                height: "unset"
            }}
        >

            <UserCalendar
                urlYear={match?.params?.year}
                urlMonth={match?.params?.month}
            />

        </Header>
    );
}
