import React, { useMemo } from 'react'
import { Text } from 'components'
import {
    ServiceCalendarItemStyle
} from './style';
import { DETAILS, LOGISTICS_SERVICES } from 'constants/paths';

export default function ServiceCalendarItem({
    service,
    index,
}) {
    const destinationName = useMemo(
        () => service?.places?.slice?.()?.pop?.()?.name,
        [service]
    );

    return (
        <ServiceCalendarItemStyle
            background={index % 2 ? "#344BC9" : "#1A2564"}
            as="a"
            href={LOGISTICS_SERVICES + "/" + service.id + DETAILS}
        >
            <Text
                fontSize="10px"
                text-overflow="ellipsis"
                overflow="hidden"
                white-space="nowrap"
                color="#fff"
            >
                {destinationName}
            </Text>
        </ServiceCalendarItemStyle>
    )
}
