import styled from 'styled-components';

export const UserServiceWrapper = styled.div(props => ({
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&>div:first-child": {
        display: "flex",
        margin: "0 0 20px 0"
    },
    borderRadius: "0 0 20px 20px",
    ["@media" + props.theme.device.laptopM]: {
        borderRadius: "0",
    },
    ...props,
}))