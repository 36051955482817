import React, { memo } from "react";
import DefaultProfileImage from "assets/images/default.avatar.png";
import {
    Text,
    Col,
} from "components";
import { localizedStrings } from "constants/localizedStrings";
import { Row } from "reactstrap";
import {
    DefaultUserImage,
    NameDivWrapper,
    ImageWrapper
} from './style';

const rowStyle = {
    display: "inline-flex",
    padding: "12px 10px",
    alignItems: "center",
    margin: "0px",
}

export default memo(function UserProfile({
    name = "Dayvison Barbosa (teste)"
}) {
    return (
        <Row style={rowStyle}>
            <Col xl="3" xxl="3" xxs="3" >
                <ImageWrapper>
                    <DefaultUserImage
                        src={DefaultProfileImage} alt="company logo" />
                </ImageWrapper>
            </Col>
            <Col xl="9" xxl="9" xxs="9" >
                <NameDivWrapper>
                    <Text
                        fontSize="16px"
                        color="#505050"
                        marginBottom="5px"
                    >
                        {localizedStrings.logisticsServices.welcome}
                    </Text>

                    <Text
                        fontWeight="bold"
                        fontSize="20px"
                        color="#505050"
                    >
                        {name}
                    </Text>
                </NameDivWrapper>
            </Col>
        </Row>
    )
})
