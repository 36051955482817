import React, { forwardRef, useEffect, useState } from "react";
import {
  InputDefault,
  IconInputRow
} from "./style.js";
import { Text, Icon } from "components";
import { localizedStrings } from "constants/localizedStrings";
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';

$.jMaskGlobals = {
  translation: {
    '0': { pattern: /\d/ },
    '9': { pattern: /\d/, optional: true },
    '#': { pattern: /\d/, recursive: true },
    'A': { pattern: /[a-zA-Z0-9 ãõñÃÕÑ~˜áéíóúÁÉÍÓÚçÇ]/ },
    'S': { pattern: /[a-zA-Z ]/ },
    '@': { pattern: /[\w@\-.+]/, recursive: true },
    // eslint-disable-next-line
    'G': { pattern: /^([a-zA-Z0-9\+\-\=\.\_\/çÇ]*)$/, recursive: true }
  }
}

const getMaskWithCustomLength = ({ array = [], length = 250, type = "S" }) => {
  array.length = length;
  return array.join(type);
};

const Input = (
  {
    type,
    maxLength,
    showMask = false,
    errorTitle = "",
    error = false,
    noMask = false,
    mask,
    ...option
  },
  ref
) => {
  const [masks] = useState([
    "phone",
    "cpf",
    "cnpj",
    "driver_license",
    "zipcode",
    "plate",
    "date",
    "datetime",
    "liters",
    "time",
    "text",
    "number",
    "money",
    "email",
    "custom",
  ])
  const [maskTypes] = useState({
    phone: () => localizedStrings.phoneMask,
    cpf: () => localizedStrings.userIdentificationMask,
    cnpj: () => localizedStrings.companyIdentificationMask,
    plate: () => localizedStrings.plateMask,
    driver_license: () => localizedStrings.driverLicenseMask,
    datetime: () => "00/00/0000 00:00",
    time: () => "00:00",
    zipcode: () => localizedStrings.zipcodeMask,
    money: () => "000.000.000.000.000,00",
    liters: () => "000.000.000.000.000,000",
    number: () => getMaskWithCustomLength({ length: maxLength, type: "0" }),
    text: () => getMaskWithCustomLength({ length: maxLength, type: "A" }),
    email: () => getMaskWithCustomLength({ length: maxLength, type: "@" }),
    custom: () => mask,
    undefined: () => getMaskWithCustomLength({ length: maxLength, type: type.mask }),
  });

  useEffect(() => {
    try {
      const selectedMask = maskTypes[masks.find(mask => type === mask)]();
      if (option.name && !noMask) {
        $("#" + option.name)
          .mask(selectedMask, { selectOnFocus: true, ...option.maskOptions })
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line 
  }, [])


  return (
    option.component
      ? option.component({ ...option, })
      :
      <>
        <IconInputRow {...option.inputDiv}>
          <InputDefault
            error={error}
            type={type}
            id={option.name}
            ref={ref}
            as={"input"}
            {...option}
          />
          {
            option?.iconOptions?.icon &&
            <Icon
              icon={option.iconOptions.icon}
              width={'12px'}
              height={'12px'}
              color='#1A237A'
              {...option.iconOptions}
              divProps={{
                position: "absolute",
                right: "0",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "40px",
                padding: "0 0 0 12px",
                cursor: "pointer",
                ...option.iconOptions?.divProps,
              }}
            />
          }
        </IconInputRow>
        {error && errorTitle
          ? (
            <Text color="#FD3995" size="10px" >
              {errorTitle}
            </Text>
          )
          : null}
      </>
  );
}
export default forwardRef(Input)


