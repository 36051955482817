import React from 'react'
import { LoadingSpin } from 'components';
import { LoadingWrapper } from './style';

export default function ScreenLoading({
    loading,
    ...props
}) {
    return (
        <LoadingWrapper>
            <LoadingSpin
                show={loading}
                weight={"5px"}
                size={"40px"}
                position="absolute"
                backColor="#1a2454"
                frontColor="transparent"
                {...props}
            />
        </LoadingWrapper>
    )
}
