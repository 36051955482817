import React, { useEffect } from "react";
import {
    Header,
    UserServices,
    UserProfile,
} from "containers";
import { useDispatch, useSelector } from "react-redux";
import {
    loadServices, loadNotifications,
} from "store/modules";
import "react-virtualized/styles.css";

import { MAX_LENGHT } from "constants/environment";

export default function LogisticsServicesOverview({ history, title, subtitle, icon }) {

    const dispatch = useDispatch();

    const {
        user: {
            name,
            id: currentLoggedUserId
        }
    } = useSelector(state => state.auth);


    const loadOrganizationServices = () => {
        dispatch(
            loadServices({
                limit: MAX_LENGHT,
                offset: 0,
                place_ids: [currentLoggedUserId],
            })
        );
    };

    const loadOrganizationNotifications = () => {
        dispatch(
            loadNotifications({
                limit: MAX_LENGHT,
                offset: 0,
            })
        );
    };

    useEffect(() => {
        
        loadOrganizationNotifications();
        loadOrganizationServices();
        // eslint-disable-next-line
    }, []);


    return (
        <Header
            type="overview"
            contentStyle={{
                background: "#F8F8FB"
            }}
        >

            <UserProfile name={name} />
            <UserServices />

        </Header>
    );
}
