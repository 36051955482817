import styled from "styled-components";

export const PageBackgroundStyle = styled.div(props => ({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  alignItems: "center",
  background: "#F8F8FB",
  height: "inherit",
  "& >div": {
    maxWidth: "1024px",
    width: "100%",
    height: "100%",
  },
  "& > div:first-child": {
    background: "#FFFFFF",
    borderRadius: "20px",
  },
  ["@media" + props.theme.device.laptopM]: {
    alignItems: "end",
    height: "100%",
    "& > div:first-child": {
      background: "#FFFFFF",
      borderRadius: "0px",
      margin: "0px",
      height: "100%",
    },
  }
}))
