import React from 'react'
import {
    Text,
    // Icon
} from 'components'
import {
    NotificationItemStyle,
    NotificationWrapper,
    NotificationContent,
} from './style'
import { format } from 'date-fns';

export default function NotificationItem({
    style = {},
    notification = {},
}) {

    return (
        <NotificationWrapper
            {...style}
        >
            {
                notification.isFirstNotificationOfDay
                && <div className="notificationDate">
                    <Text
                        fontSize="16px"
                        color="#505050"
                        padding="12px 0"
                    >
                        {format(new Date(notification.date), "dd/MM/yyyy")}
                    </Text>
                </div>
            }
            <NotificationItemStyle active={notification.status}>
                <NotificationContent >
                    <div >
                        <Text
                            fontWeight="bold"
                            fontSize="17px"
                            color="#505050"
                            textOverflow="ellipsis"
                            marginBottom="5px"
                            overflow="hidden"
                        >
                            {notification.title}
                        </Text>
                    </div>
                    <div>
                        <Text
                            fontSize="13px"
                            color="#505050"
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {notification.description}
                        </Text>
                    </div>
                </NotificationContent>
            </NotificationItemStyle>


        </NotificationWrapper>
    )
}
