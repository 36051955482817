import React from 'react';
import { DayStyle } from './style';
import { Text } from 'components';
import ServiceCalendarItem from '../serviceCalendarItem';

export default function Day({
    day,
    services
}) {

    return (
        <DayStyle
        >
            <Text
                textAlign="center"
                color="#4E4E4E"
                fontSize="14px"
                padding="5px 0"
            >
                {
                    isNaN(day)
                        ? " "
                        : day
                }
            </Text>
            {
                services?.map?.((service, index) => (
                    <ServiceCalendarItem
                        key={index}
                        index={index}
                        service={service}
                    />
                ))
            }
        </DayStyle>
    )
}
