import React, { useEffect, useMemo } from 'react';
import Header from './header';
import Month from './month';
import MonthsPaginator from './monthsPaginator';
import {
    CalendarStyle
} from './style'
import { useDispatch } from 'react-redux';
import { setCalendarConfiguration } from 'store/modules';
import { getMonth, isDate, getYear } from 'date-fns';

export default function Calendar({
    date = new Date(),
    urlYear,
    urlMonth,
}) {
    const dispatch = useDispatch();

    useEffect(() => {

        try {
            let selectedDate = new Date(date);

            const hasError = [
                +urlMonth < 0,
                +urlYear < 0,
                isNaN(+urlYear),
                isNaN(+urlMonth),
                !isDate(selectedDate),
            ].some(Boolean);

            if (hasError) selectedDate = new Date();

            dispatch(setCalendarConfiguration({ date: selectedDate }));
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, [date]);

    const month = useMemo(
        () => {

            const hasError = [
                +urlMonth < 0,
                +urlYear < 0,
                isNaN(+urlYear),
                isNaN(+urlMonth),
            ].some(Boolean);

            if (hasError) return getMonth(new Date()) + 1;

            return urlMonth;
        },
        // eslint-disable-next-line
        [urlMonth]
    );

    const year = useMemo(
        () => {
            const hasError = [
                +urlMonth < 0,
                +urlYear < 0,
                isNaN(+urlYear),
                isNaN(+urlMonth),
            ].some(Boolean);

            if (hasError) return getYear(new Date());

            return urlYear;
        },
        // eslint-disable-next-line
        [urlYear]
    );

    return (
        <CalendarStyle >
            <Header />
            <Month />
            <MonthsPaginator
                month={month - 1}
                year={year}
            />
        </CalendarStyle>
    )
}
