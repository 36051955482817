import React, { memo } from "react";
import {
    Link,
    Icon,
} from "components";
import {
    ImageWrapper
} from './style';
import Logo from "assets/images/logo.png";
import { useHistory } from "react-router-dom";

export default memo(function CalendarHeader({
    children,
    contentStyle,
}) {

    const history = useHistory();

    return (
        <>
            <div>
                <Link
                    padding="8px 12px 12px 12px"
                    href={"#"}
                    onClick={() => {
                        history.goBack()
                    }}
                    position="absolute"
                    left="10px"
                >
                    <Icon
                        icon={"arrow"}
                        width="10px"
                        height="10px"
                        color="#192366"
                        divProps={{
                            transform: "rotate(90deg) scale(1)"
                        }}
                    />
                </Link>
            </div>
            <ImageWrapper >
                <img src={Logo} alt="company logo" />
            </ImageWrapper>
        </>
    );
})