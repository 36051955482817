import styled from 'styled-components'

export const LoadingWrapper = styled.div(props => ({
    height: props.height || "300px",
    display: props.display || "flex",
    justifyContent: props.justifyContent || "center",
    alignItems: props.alignItems || "center",
    flexDirection: props.flexDirection || "column",
    padding: props.padding || "20px 0 20px 0",
    position: "relative"
}))