import styled from 'styled-components';

export const VehicleDetailsDiv = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    justifyContent: "space-between",
    height: "100%"
}))
export const VehicleCard = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    background: "transparent",
}))
export const VehicleInformations = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    background: "#fff",
    boxShadow: "0px 0px 5px #00000050",
    width: "100%",
    borderRadius: "5px",
    padding: "18px",
}))

export const VehicleData = styled.div(props => ({
    "&>div": {
        padding: "5px 0"
    }
}))
