import styled from 'styled-components';

export const UserNotificationsWrapper = styled.div(props => ({
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    "& div.notificationDate": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    borderRadius: "0 0 20px 20px",
    ["@media" + props.theme.device.laptopM]: {
        borderRadius: "0",
    },
    ...props,
}))