import React from "react";
import {
    ServiceRoute, 
} from "components";
import {
    UserServiceWrapper,
} from './style';

export default function UserServiceRoute() {

    return (
        <UserServiceWrapper>
            <ServiceRoute />
        </UserServiceWrapper>
    )
}
