export default {
    welcome: "Seja bem-vindo!",
    servicesListedBelow: "Veja abaixo os serviços cadastrados ao seu perfil.",
    calendar: "calendário",
    details: "Detalhes",
    qrcode: "qr code para embarque",
    qrcodeDepartureAndArrival: "qr code para embarque e desembarque",
    cancel: "cancelar",
    carTransportation: "Transporte de carro",
    passengerTransportation: "Transporte de Passageiro Programado",
    boardingTime: "Hora do embarque",
    landing: "Desembarque",
    driver: "Motorista",
    vehicleTurnOn: "Veículo ligado",
    vehicleTurnOff: "Veículo desligado",
    vehicleNoSignal: "Veículo sem sinal no momento",
    vehicleNoSignalForMoreThen24Hours: "Veículo sem sinal há mais de 24h",
    vehiclesTurnOn: "Veículos ligados",
    vehiclesTurnOff: "Veículos desligados",
    vehiclesNoSignal: "Veículos sem sinal no momento",
    vehiclesNoSignalForMoreThen24Hours: "Veículos sem sinal há mais de 24h",
    departurePlace: "Local de embarque",
    arrivePlace: "Local de desembarque",
    and: "e",
    questionCancel: "Deseja cancelar esse serviço?",
    arrival: "Você chegou ao seu destino!",
    questionHowWasYourTrip: "Como foi sua viagem?",
    rating: {
        veryBad: "péssima",
        bad: "ruim",
        ok: "boa",
        good: "muito boa",
        veryGood: "ótima",
    },
    questionObservation: "Deseja deixar alguma observação?",
    placeholderObservation: "Deseja sua observação aqui",
    sendEvaluation: "Enviar avaliação",
    close: "Fechar",
    evaluationSendSuccessfully: "Sua avaliação foi enviada com sucesso",
    thankYouForEvaluating: "Obrigado por avaliar!",
    serviceUnavailable: "Serviço indisponível no momento",
    emptyStateText: "Nenhum serviço cadastrado",
    serviceCancelled: "Serviço cancelado com sucesso!",
    errorWhenServiceCancelled: "Erro ao cancelar serviço",
    km: "km",
    OfDistance: "de distância",
    weekDayNotAllowed: "Dia da semana não incluído no serviço",
}