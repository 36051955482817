import styled from 'styled-components';

export const UserServiceWrapper = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "0 0 20px 20px",
    position: "relative",
    flex: "1",
    ...props,
}))
