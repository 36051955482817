import styled from 'styled-components';

export const DefaultUserImage = styled.img(props => ({
    width: "60px",
    height: "60px",
    borderRadius: "35px",
    ...props,
}))
export const NameDivWrapper = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    ...props,
}))
export const ImageWrapper = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    ...props,
}))