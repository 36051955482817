import React from 'react'
import {
    Text,
    // Icon
} from 'components'
import { localizedStrings } from 'constants/localizedStrings'
import {
    ServiceItemStyle,
    ServiceWrapper,
    ServiceContent,
    ServiceLink,
} from './style'
import {
    LOGISTICS_SERVICES,
    DETAILS,
} from 'constants/paths'
export default function ServiceItem({
    index,
    style = {},
    service = {},
}) {

    return (
        <ServiceWrapper
            {...style}
        >
            <ServiceItemStyle
                as="a"
                href={LOGISTICS_SERVICES + "/" + service.id + DETAILS}
            >
                {/* 
                icon temporarily removed:
                <ServiceIconDiv >
                    <Icon icon="car" width="20px" height="20px" color="#3B399226" />
                </ServiceIconDiv> 
                */}
                <ServiceContent >
                    <div >
                        <Text
                            fontWeight="bold"
                            fontSize="17px"
                            color="#505050"
                            textOverflow="ellipsis"
                            marginBottom="5px"
                            width="80%"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            {service?.name}
                        </Text>
                    </div>
                    <div>
                        <Text
                            fontSize="13px"
                            color="#505050"
                            textOverflow="ellipsis"
                            width="218px"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            {service.type_service_name}
                        </Text>
                    </div>
                </ServiceContent>
                <ServiceLink >
                    <Text
                        fontSize="13px"
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        color="#1a2565"

                    >
                        {localizedStrings.logisticsServices.details}

                    </Text>
                </ServiceLink>
            </ServiceItemStyle>
        </ServiceWrapper>
    )
}
