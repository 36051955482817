export const NOTIFICATION_CHANGE_OPERATION_STATES = "notification_change_operation_states";
export const NOTIFICATION_LOAD_SUCCESS = "notification_load_success";
export const NOTIFICATION_CLEAR_SUCCESS = "notification_clear_success";

const operationStates = {
  loadLoading: false,
  loadSuccess: false,
  loadFail: false,
  updateLoading: false,
  updateSuccess: false,
  updateFail: false,
}

const INITIAL_STATE = {
  notifications: [],
  total: 0,
  hasNotifications: false,
  ...operationStates,
};

export default function notifications(state = INITIAL_STATE, action) {
  const actionTypes = {
    service_change_operation_states() {
      return {
        ...state,
        ...action.payload
      };
    },
    notification_load_success() {
      return {
        ...state,
        notifications: action.payload.notifications,
        total: action.payload.total,
        hasNotifications: action.payload.hasNotifications,
      };
    },
    notification_clear_success() {
      return {
        ...state,
        notifications: [],
        total: 0,
        hasNotifications: false,
      };
    },
  };

  if (actionTypes[action.type]) return actionTypes[action.type]();
  return state;
}
