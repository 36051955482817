import React, { useMemo } from "react";
import {
    Calendar
} from "components";

import {
    UserCalendarWrapper
} from './style'


export default function UserCalendar({
    urlYear,
    urlMonth,
}) {
    const selectedDate = useMemo(
        () => {
            const parsedMonth = +urlMonth - 1;

            const hasError = [
                +urlMonth < 0,
                +urlYear < 0,
                !+urlYear,
                !+urlMonth,
            ].some(Boolean);

            if (hasError) return new Date();

            return new Date(urlYear, +parsedMonth);
        },
        [urlYear, urlMonth]
    );

    return (
        <UserCalendarWrapper>
            <Calendar
                date={selectedDate}
                urlYear={urlYear}
                urlMonth={urlMonth}
            />
        </UserCalendarWrapper>
    )
}
