import styled from 'styled-components';

const status_colors = {
    not_started: '#222',
    in_progress: '#0795FA',
    paused: '#FAA628',
    finished: '#22C414',
    inactive: '#FF3D3D',
    default: "#22C414"
}

export const CurrentPlaceDetailsStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    position: "relative",
    flex: "1",
    "& span": {
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
    },
    "& span:first-letter": {
        textTransform: "capitalize",
    },
    "& img": {
        minHeight: "220px",
        padding: "10px 0",
    }
}))
export const StopHoursStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    "&>div": {
        padding: "16px 0 0",
        flexDirection: "column",
        display: "flex",
        flex: "1",
    }

}))

export const DetailsDiv = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #DDDDDD",
}));

export const Status = styled.div(props => ({
    background: status_colors[props.status] || status_colors.default,
    width: "20px",
    height: "20px",
    borderRadius: "8px",
    marginTop: "10px",
}));

