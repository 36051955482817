export const VEHICLE_LOAD_SUCCESS = "vehicle_load_success";
export const VEHICLE_CHANGE_OPERATION_STATES = "vehicle_change_operation_states";


const operationStates = {
  loadLoading: false,
  loadSuccess: false,
  loadFail: false,
  createLoading: false,
  createSuccess: false,
  createFail: false,
}

const INITIAL_STATE = {
  vehicles: [],
  total: 0,
  pointsHistory: {},
  lastPoints: [],
  statusSummary: {},
  ...operationStates,
};

export default function vehicles(state = INITIAL_STATE, action) {
  const actionTypes = {
    vehicle_load_success() {
      return {
        ...state,
        ...action.payload
      };
    },
    vehicle_change_operation_states() {
      return {
        ...state,
        ...action.payload
      };
    },
  };

  if (actionTypes[action.type]) return actionTypes[action.type]();
  return state;
}
