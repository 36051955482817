import React from "react";
import {
  Link,
  Icon
} from "components";
import Logo from "assets/images/logo.png";
import {
  logout
} from "store/modules";
import {
  ImageWrapper
} from './style';
import { useDispatch } from "react-redux";

export default function OverviewHeader({
  children,
  contentStyle,
}) {
  const dispatch = useDispatch();

  return (
    <>
      <ImageWrapper >
        <img src={Logo} alt="company logo" />
      </ImageWrapper>
      {/* <div>
            <Link
              padding="8px 12px"
              href={LOGISTICS_SERVICES_NOTIFICATIONS_PATH}
            >
              <Icon
                icon={hasNotifications ? "bell-with-notification" : "bell"}
                width="20px"
                height="20px"
                color="#0157B3"
              />
            </Link>
          </div> */}
      <div>
        <Link
          padding="8px 12px"
          href={"#"}
          onClick={() => {
            dispatch(
              logout()
            )
          }}
          position="absolute"
          right="10px"
        >
          <Icon
            icon={"leave"}
            width="20px"
            height="20px"
            color="#1a2565"
          />
        </Link>
      </div>
    </>
  );
}
