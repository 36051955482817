import styled from "styled-components";
import "../../assets/fonts.css";

export const EndDestinyName = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
    backgroundColor: "#1D1B84",
    borderRadius: "8px",
    marginBottom: "2px",
    ...props
}))
export const EndDestinyPinWrapper = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    flexDirection: "column",
    ...props
}))
export const EndDestinyPinDiv = styled.div(props => ({
    display: "flex",
    alignItems: "center",
    ...props
}))
