import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  Login,
  LogisticsServicesOverview,
  LogisticsServicesDetails,
  LogisticsServicesRoute,
  LogisticsServicesCalendar,
  LogisticsServicesNotifications,
  LogisticsServicesEvaluation
} from "./pages/";

import {
  LOGIN_PATH,
  LOGISTICS_SERVICES_MANAGE_PATH,
  LOGISTICS_SERVICES_CALENDAR_PATH,
  LOGISTICS_SERVICES_NOTIFICATIONS_PATH,
  DETAILS,
  LOGISTICS_SERVICES,
  ROUTE,
  EVALUATION,
} from "constants/paths";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component, ...options }) => {
  const {
    // eslint-disable-next-line 
    user: { id, token }
  } = useSelector(state => state.auth);

  const isAuthenticated = !!id && !!token;

  if (isAuthenticated) {
    return <Route {...options} component={component} />;
  }

  return <Redirect from={options.location.pathname} to={LOGIN_PATH} />;
};

const LoginRoute = ({ component, ...options }) => {
  const {
    user: { id, token }
  } = useSelector(state => state.auth);

  const isAuthenticated = !!id && !!token;

  if (!isAuthenticated) {
    return <Route {...options} component={component} />;
  }

  return <Redirect to={LOGISTICS_SERVICES_MANAGE_PATH} />;
};

export default function Router() {

  return (
    <Switch>
      <PrivateRoute
        exact
        path={LOGISTICS_SERVICES_MANAGE_PATH}
        component={props => (
          <LogisticsServicesOverview
            {...props}
          />
        )}
      />
      <PrivateRoute
        exact
        path={LOGISTICS_SERVICES + "/:id" + DETAILS}
        component={props => (
          <LogisticsServicesDetails
            {...props}
          />
        )}
      />
      <PrivateRoute
        exact
        path={LOGISTICS_SERVICES + "/:id" + ROUTE}
        component={props => (
          <LogisticsServicesRoute
            {...props}
          />
        )}
      />
      <PrivateRoute
        exact
        path={LOGISTICS_SERVICES + "/:id" + EVALUATION}
        component={props => (
          <LogisticsServicesEvaluation
            {...props}
          />
        )}
      />
      <PrivateRoute
        exact
        path={LOGISTICS_SERVICES_CALENDAR_PATH + "/:year/:month/"}
        component={props => (
          <LogisticsServicesCalendar
            {...props}
          />
        )}
      />
      <PrivateRoute
        exact
        path={LOGISTICS_SERVICES_NOTIFICATIONS_PATH}
        component={props => (
          <LogisticsServicesNotifications
            {...props}
          />
        )}
      />

      <LoginRoute path={LOGIN_PATH} component={Login} />
      <Redirect from="/" to={LOGISTICS_SERVICES_MANAGE_PATH} />

    </Switch>
  );
}

export { Router };
