import React from 'react';
import { EndDestinyPinDiv, EndDestinyName, EndDestinyPinWrapper } from './style';
import { Text } from 'components';
import pinEnd from 'assets/images/finish.png';

export default function EndDestinyPin({ hasIcon = true, showVehicleName = false, optionsWrapper = {}, ...options }) {
    return (
        <EndDestinyPinWrapper onClick={options?.onClick} {...optionsWrapper}>
            {
                showVehicleName &&
                <EndDestinyName>
                    <Text color={"#fff"}>
                        {options.name}
                    </Text>
                </EndDestinyName>
            }
            <EndDestinyPinDiv>
                {
                    hasIcon &&
                    <img src={pinEnd} alt="end pin" />
                }
            </EndDestinyPinDiv>
        </EndDestinyPinWrapper>
    );
}