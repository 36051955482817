import React, { useState, useEffect, useMemo } from 'react'
import Day from '../day';
import {
    WeekStyle
} from './style';
import { getDaysInMonth, format, getYear, getMonth, isDate, } from 'date-fns';
import { useSelector } from 'react-redux';

const maxDaysInWeek = 7;

const distanceBetweenSundayToOtherDays = {
    sun: 0,
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
};

export default function Week({
    weekIndex,
    startOfCurrentMonth,
    numberOfweeks,
    servicesInMonth,
}) {
    const [daysRangeOfCurrentWeek, setDaysRangeOfCurrentWeek] = useState([]);

    const {
        date
    } = useSelector(state => state.calendar);

    const calendarYear = useMemo(
        () => getYear(date),
        [date],
    )
    const calendarMonth = useMemo(
        () => getMonth(date) + 1,
        [date],
    )

    const daysInCurrentMonth = useMemo(
        () => {
            try {

                if (!isDate(date)) throw new Error("The given date is an invalid date");

                return getDaysInMonth(date);
            } catch (error) {

                return getDaysInMonth(new Date())
            }
        },
        [date]
    );

    const getDaysToCurrentWeek = ({
        daysRange,
        daysListWithOffset,
    }) => {

        for (let day = daysRange.start; day < daysRange.end; day++) {

            if (day > daysInCurrentMonth) continue;

            daysListWithOffset.push(day);

        };

        return daysListWithOffset

    };

    const getWeekConfiguration = ({
        isFirstWeek
    }) => {
        const firstDayOfMonth = format(startOfCurrentMonth, "E").toLowerCase();


        const daysOffset = distanceBetweenSundayToOtherDays[firstDayOfMonth];

        const hasDayOffset = daysOffset !== 0;

        const daysRange = {
            start: (weekIndex - 1) * maxDaysInWeek,
            end: (weekIndex * maxDaysInWeek),
        }

        if (hasDayOffset) {
            // start of the month could be any day of the week, so
            // to display properly, we need offset the initial day of each week
            if (!isFirstWeek) daysRange.start -= daysOffset

            daysRange.end -= daysOffset
        }

        if (daysRange.end > daysInCurrentMonth) daysRange.end = daysInCurrentMonth;

        return {
            daysRange,
            daysOffset,
        }
    }

    useEffect(() => {

        const isFirstWeek = weekIndex === 1;

        const {
            daysRange,
            daysOffset,
        } = getWeekConfiguration({
            isFirstWeek
        });

        const daysArray = {
            withoutOffset: [],
            withOffset: [],
        }

        if (isFirstWeek) {
            // the first week has days without content
            // so we need to offset the initial date
            daysArray.withoutOffset.length = daysOffset;

            daysArray.withOffset = Array.from(daysArray.withoutOffset);

        }

        const daysRangeOfWeekWithOffset = getDaysToCurrentWeek({
            daysRange,
            daysListWithOffset: daysArray.withOffset,
        });

        setDaysRangeOfCurrentWeek(daysRangeOfWeekWithOffset);
        // eslint-disable-next-line
    }, [startOfCurrentMonth,]);

    const filterServicesInCurrentDay = ({
        service,
        currentDay
    }) => {

        const currentDate = format(new Date(calendarYear, calendarMonth - 1, currentDay), "yyyy-MM-dd");

        const serviceStartDay = format(new Date(service.start_date), "yyyy-MM-dd");

        const serviceEndDay = format(new Date(service.end_date), "yyyy-MM-dd");

        const startDateIsBeforeCurrentDay = currentDate > serviceStartDay;

        const currentDayIsBeforeEndDate = currentDate < serviceEndDay;

        const serviceIsBetweenRange = startDateIsBeforeCurrentDay && currentDayIsBeforeEndDate;

        return serviceIsBetweenRange;
    };

    const getServicePerDay = (services, currentDay) => {

        const currentDayIsOffset = !Number.isInteger(currentDay);

        if (currentDayIsOffset) return services;

        const hasServicesInCurrentDay = Array.isArray(services?.[currentDay]);

        if (!hasServicesInCurrentDay) services[currentDay] = [];

        const servicesInCurrentDay = servicesInMonth
            .filter((service) => filterServicesInCurrentDay({ service, currentDay }));

        const hasServicesInDay = Array.isArray(servicesInCurrentDay) && servicesInCurrentDay.length > 0;

        if (hasServicesInDay) services[currentDay].push(...servicesInCurrentDay)

        return services;
    }

    const servicesFromCurrentWeek = useMemo(() => {

        const hasDaysRange = Array.isArray(daysRangeOfCurrentWeek) && daysRangeOfCurrentWeek.length > 0;

        const hasServicesInMonth = Array.isArray(servicesInMonth) && servicesInMonth.length > 0;

        if (!hasDaysRange || !hasServicesInMonth) return [];

        const servicesPerDay = daysRangeOfCurrentWeek.reduce(getServicePerDay, {});

        return servicesPerDay;
        // eslint-disable-next-line
    }, [daysRangeOfCurrentWeek, servicesInMonth]);

    return (
        <WeekStyle
            numberOfweeks={numberOfweeks}
        >
            {
                daysRangeOfCurrentWeek?.map?.(index => {

                    const day = index + 1;

                    const servicesInCurrentDay = servicesFromCurrentWeek?.[index] || [];

                    return (
                        <Day
                            key={index}
                            services={servicesInCurrentDay}
                            day={day}
                        />
                    )
                })
            }
        </WeekStyle>
    )
}
