// // import { localizedStrings } from "constants/localizedStrings";

export const SOLICITATION_CHANGE_OPERATION_STATES = "service_change_operation_states";
export const SOLICITATION_CREATE_SUCCESS = "service_create_success";
export const SERVICE_LOAD_SUCCESS = "service_load_success";
export const PROGRESS_LOAD_SUCCESS = "progress_load_success";
export const CHANGE_CURRENT_STEP = 'change_current_step';

const operationStates = {
  loadLoading: false,
  loadSuccess: false,
  loadFail: false,
  updateLoading: false,
  updateSuccess: false,
  updateFail: false,
}

const createSteps = [
  {
    show: true,
    // name: localizedStrings.steps.one.name,
    // description: localizedStrings.steps.one.description,
    active: true,
    success: false,
  },
  {
    show: true,
    // name: localizedStrings.steps.two.name,
    // description: localizedStrings.steps.two.description,
    active: false,
    success: false,
  },
  {
    show: true,
    // name: localizedStrings.steps.three.name,
    // description: localizedStrings.steps.three.description,
    active: false,
    success: false,
  },
  {
    show: false,
    active: false,
    success: false,
  }
]

const INITIAL_STATE = {
  services: [],
  total: 0,
  serviceProgress: [],
  service: {},
  servicePlaces: [],
  selectors: {},
  searchedAddress: {},
  steps: createSteps,
  ...operationStates,
};

export default function services(state = INITIAL_STATE, action) {
  const actionTypes = {
    change_current_step() {
      return {
        ...state,
        steps: state.steps.map((step, index) => {
          if (action.payload.stepIndex !== index) return {
            ...step,
            active: false,
            success: index < action.payload.stepIndex,

          };
          return {
            ...step,
            active: true,
            success: false,
          }
        })
      }
    },
    service_change_operation_states() {
      return {
        ...state,
        ...action.payload
      };
    },
    service_create_success() {
      return {
        ...state,
        services: [...state.services, action.payload.service],
      };
    },
    service_load_success() {
      return {
        ...state,
        ...action.payload,
      };
    },
    progress_load_success() {
      return {
        ...state,
        ...action.payload,
      };
    },
  };

  if (actionTypes[action.type]) return actionTypes[action.type]();
  return state;
}
