import { METER_BETWEEN_VEHICLE_AND_END_LOCATION } from "constants/environment";
import { LOGISTICS_SERVICES_MANAGE_PATH } from "constants/paths";
import { endOfDay, isWithinInterval } from "date-fns";
import { getPreciseDistance } from "geolib";
const getPendentServices = ({
    logistics = [],
    place_ids = [],
}) => {
    return logistics.filter(service => {
        const placeProgress = service?.progress?.filter?.(p => place_ids.includes(+p.place_id)) || [];

        const placesLogged = service?.places?.filter?.(p => place_ids.includes(+p.place_id)) || [];

        const placeProgressLength = placeProgress.length;

        const placesLoggedLength = placesLogged.length;

        const hasPendentPlaces = placeProgressLength < placesLoggedLength;

        return hasPendentPlaces;
    });
};


const verifyIfUserProgressStatus = ({
    service,
    userId
}) => {
    try {
        const types = ["in", "out"];

        const typesLength = types.length;

        const placesLogged = service?.places?.filter?.(p => +userId === +p.place_id) || [];

        const placeProgress = service?.progress?.filter?.(p => +userId === +p.place_id) || [];

        const progressLength = placeProgress.length;

        const placesLength = placesLogged.length;

        const hasInAndOutType = placesLogged.some(p => p.type === 'out');

        if (hasInAndOutType) {

            return {
                embarked: !!progressLength && (progressLength % typesLength !== 0),
                arrived: !!progressLength && (progressLength % typesLength === 0),
            };
        }

        return {
            embarked: progressLength === placesLength,
            arrived: undefined,
        }

    } catch (error) {

        console.log(error);

        return {
            embarked: undefined,
            arrived: undefined,
        }
    }
}

const verifyIfUserHasCompletedProgress = ({
    service,
    userId
}) => {

    const placeProgress = service?.progress?.filter?.(p => +userId === +p.place_id) || [];

    const placesLogged = service?.places?.filter?.(p => +userId === +p.place_id) || [];

    const hasArrived = placeProgress.length === placesLogged.length

    return hasArrived;
}


const verifyIfServiceHasLoggedPlace = ({
    places,
    userId
}) => {
    const hasCurrentLoggedPlace = places
        .filter(place => +place.place_id === +userId)
        .length;

    return !!hasCurrentLoggedPlace;
}


const isServiceBetweenDayRange = ({
    service
}) => {
    const today = new Date();

    const startDate = new Date(service.start_date);

    const endDate = endOfDay(new Date(service.end_date))

    const isServiceBetweenDayRange = isWithinInterval(today, {
        start: startDate,
        end: endDate
    });

    return isServiceBetweenDayRange;
}

const getValidExceptions = ({
    exceptions,
    now = new Date(),
    placeType = "in",
}) => exceptions
    .filter(exception => {
        try {
            const isWithinPeriod = isWithinInterval(now, {
                start: new Date(exception.start_date),
                end: new Date(exception.end_date)
            });

            const [
                hasLatitude,
                hasLongitude,
                isSameType
            ] = [
                    exception.lat,
                    exception.lng,
                    placeType === exception.type
                ];

            const isValidException = isWithinPeriod && hasLatitude && hasLongitude && isSameType;

            return isValidException;
        } catch (error) {
            return false;
        }
    }).map(exception => ({
        ...exception,
        isException: true,
    }));

const verifyIfHasToRedirectToEvaluation = ({
    vehiclePosition,
    endPlace,
    service,
}) => {
    const distanceInMetersBetweenVehicleAndEndLocation = getPreciseDistance(vehiclePosition, endPlace);

    const vehicleIsCloseToDestiny = distanceInMetersBetweenVehicleAndEndLocation <= METER_BETWEEN_VEHICLE_AND_END_LOCATION;

    const evaluateStatus = {
        in_progress: true,
        paused: true,
        finished: true,
    };

    const serviceIsEvaluable = evaluateStatus[service?.type] === true;

    const hasToRedirect = serviceIsEvaluable && vehicleIsCloseToDestiny;

    return hasToRedirect;
}

const verifyIfUserCanEvaluateService = ({
    service,
    user,
}) => {
    const userCanEvaluateThisService = verifyIfServiceHasLoggedPlace({
        places: service?.places || [],
        userId: user.id
    });

    const hasToRedirectUser = !userCanEvaluateThisService;

    if (hasToRedirectUser) return LOGISTICS_SERVICES_MANAGE_PATH;
}

export {
    getPendentServices,
    isServiceBetweenDayRange,
    getValidExceptions,
    verifyIfUserCanEvaluateService,
    verifyIfServiceHasLoggedPlace,
    verifyIfUserHasCompletedProgress,
    verifyIfHasToRedirectToEvaluation,
    verifyIfUserProgressStatus,
}