import React, { memo } from "react";
import {
    Link,
    Icon,
} from "components";
import Logo from "assets/images/logo.png";
import {
    TitleWrapper
} from './style';
import { getMonth, getYear } from "date-fns";
import { useHistory } from "react-router-dom";
import { LOGISTICS_SERVICES_CALENDAR_PATH } from "constants/paths";

export default memo(function NotificationHeader({
    children,
    contentStyle,
}) {

    const history = useHistory();

    return (
        <>
            <div>
                <Link
                    padding="8px 12px 12px 12px"
                    href={"#"}
                    onClick={() => {
                        history.goBack()
                    }}
                    position="absolute"
                    left="10px"
                >
                    <Icon
                        icon={"arrow"}
                        width="10px"
                        height="10px"
                        color="#192366"
                        divProps={{
                            transform: "rotate(90deg) scale(1)"
                        }}
                    />
                </Link>
            </div>
            <TitleWrapper >
                <img src={Logo} alt="company logo" />
            </TitleWrapper>
            <div>
                <Link
                    padding="8px 12px 12px 12px"
                    // eslint-disable-next-line 
                    href={LOGISTICS_SERVICES_CALENDAR_PATH + "/" + +getYear(new Date()) + "/" + `${getMonth(new Date()) + 1}`}
                    hover={{
                        textDecoration: "none"
                    }}
                    position="absolute"
                    right="10px"
                >
                    <Icon
                        icon={"calendar"}
                        width="16px"
                        height="16px"
                        color="#192366"
                    />
                </Link>
            </div>
        </>
    );
})