import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    DestinationMarker,
    Map, PersonMarker, VehicleMarker
} from 'components'
import { useDispatch, useSelector } from 'react-redux';
import { Polyline } from '@react-google-maps/api';
import { getBestRouteService, getBestRouteVehicle, setPlacesToService } from 'store/modules';
import { localizedStrings } from 'constants/localizedStrings';
import { addSeconds, format } from 'date-fns';
import {
    EVALUATION,
    LOGISTICS_SERVICES
} from 'constants/paths';

import { useHistory } from 'react-router-dom';
import { getValidExceptions, verifyIfHasToRedirectToEvaluation, verifyIfUserProgressStatus } from 'utils/services';

export default function ServiceRoute() {
    const mapRef = useRef(null);

    const history = useHistory();

    const dispatch = useDispatch();


    const {
        lastPoints
    } = useSelector(state => state.vehicles);

    const {
        service,
        servicePlaces
    } = useSelector(state => state.services);

    const {
        places
    } = useSelector(state => state.places);

    const {
        serviceRoute,
        vehicleRoute,
    } = useSelector(state => state.map);

    const {
        user
    } = useSelector(state => state.auth);

    const [vehiclePosition, setVehiclePosition] = useState({ lat: 0, lng: 0 });

    const hasVehiclePosition = useMemo(
        () => vehiclePosition.lat !== 0 && vehiclePosition.lng !== 0,
        [vehiclePosition]
    );

    const [currentPlace, setCurrentPlace] = useState({});

    const [routeConfiguration, setRouteConfiguration] = useState({});

    const [orderedPlaces, setOrderedPlaces] = useState([]);

    const [hasFitBounds, setFitBounds] = useState(false);

    const [endPlace, setEndPlace] = useState({});

    const hasEndPosition = useMemo(
        () => endPlace?.lat !== 0 && endPlace.lng !== 0,
        [endPlace]
    );

    const fitBoundsOnMap = latLngArray => {
        try {
            const latlngbound = new window.google.maps.LatLngBounds();

            latLngArray
                .map(coord => ({
                    lat: coord?.lat,
                    lng: coord?.lng,
                }))
                .forEach(coord => latlngbound.extend(new window.google.maps.LatLng(coord.lat, coord.lng)));

            if (!latlngbound) return;

            mapRef.current.fitBounds(latlngbound);
        } catch (error) {
            console.log(error);
        }
    };

    const getRouteConfiguration = () => {
        const hasPlaces = places?.length > 0;

        const routeConfiguration = {
            currentPlace: {},
            orderedPlaces: [],
        }

        if (hasPlaces) {
            routeConfiguration.orderedPlaces = service?.places
                ?.map?.(place => {
                    const placeWithAllInfos = JSON.parse(JSON.stringify(
                        places.find(p => +p.id === +place.place_id) || {}
                    )) || {}

                    const newPlace = {
                        addresses: [],
                        ...placeWithAllInfos,
                        type: place?.type,
                    }

                    if (newPlace.type === 'out') {

                        newPlace.addresses = placeWithAllInfos?.addresses?.slice?.().reverse?.() || newPlace.addresses;

                    }

                    if (place?.exceptions?.length) {
                        const [firstException] = getValidExceptions({
                            exceptions: place.exceptions,
                            placeType: newPlace?.type,
                        });

                        const [placeExceptions] = newPlace.addresses.filter(a => a.isException);

                        const exceptionAlreadyExists = JSON.stringify(firstException) === JSON.stringify(placeExceptions);

                        if (firstException && !exceptionAlreadyExists) {
                            newPlace.addresses.unshift(firstException);
                            newPlace.isException = true;
                        }
                    }

                    return newPlace;
                })
            routeConfiguration.orderedPlaces.find((place, index) => {
                const placeIsSameAsLoggedUser = +place.id === +user.id;

                if (placeIsSameAsLoggedUser) {

                    routeConfiguration.currentPlace = {
                        index,
                        ...place,
                    };

                };

                return placeIsSameAsLoggedUser;
            });
        }
        return routeConfiguration
    }

    const searchRouteBetweenVehicleAndUser = ({
        currentPlace,
    }) => {
        const serviceHasPlaces = places?.length > 0;

        if (!serviceHasPlaces) return;

        const [currentAddress] = currentPlace.addresses;

        const destination = {
            lat: currentAddress.lat || false,
            lng: currentAddress.lng || false,
        };

        const origin = {
            lat: vehiclePosition.lat || false,
            lng: vehiclePosition.lng || false,
        }

        const isVehicleOnMap = [!!destination.lat, !!destination.lng, !!origin.lat, !!origin.lng].every(Boolean);

        if (!isVehicleOnMap) return;

        dispatch(getBestRouteVehicle({ latLng: { origin, destination } }));

        return {
            origin,
            destination,
        }
    }

    const searchRouteBetweenUserAndDestiny = ({
        currentPlace,
        orderedPlaces
    }) => {
        const defaultValue = {
            origin: {},
            destination: {},
            waypoints: []
        }
        try {
            const serviceHasPlaces = places?.length > 0;

            if (!serviceHasPlaces) return;

            const [currentAddress = {}] = currentPlace.addresses || [];

            const origin = {
                lat: currentAddress.lat || false,
                lng: currentAddress.lng || false,
            };

            const [destinationAddress = {}] = orderedPlaces.slice().pop().addresses || [];

            const [passengerOutAddress = {}] = orderedPlaces.find(p => p.id === currentPlace.id && p.type === 'out')?.addresses || [];

            const destination = {
                lat: passengerOutAddress.lat || destinationAddress.lat || false,
                lng: passengerOutAddress.lng || destinationAddress.lng || false,
            };

            const isVehicleOnMap = [!!destination.lat, !!destination.lng, !!origin.lat, !!origin.lng].every(Boolean);

            if (!isVehicleOnMap) return defaultValue;

            const [
                nextStop,
                lastStopBeforeFinish
            ] = [
                    currentPlace.index,
                    orderedPlaces.length - 1
                ];

            const coordinates = orderedPlaces
                .slice(nextStop, lastStopBeforeFinish) // route between current user location and destiny
                .map(place => {

                    const [firstPlaceAddress] = place?.addresses

                    return [
                        firstPlaceAddress.lat,
                        firstPlaceAddress.lng
                    ].join(",")
                });

            const waypoints = [
                ...coordinates
            ].join("|");

            dispatch(getBestRouteService({ latLng: { origin, destination }, waypoints }));

            return {
                origin,
                destination,
                waypoints
            }

        } catch (error) {
            console.log(error);

            return defaultValue
        }
    };
    const hasLatLng = position => position?.lat && position?.lng;

    const fitBounds = ({
        hasPlaces,
        currentPlace,
        destination,
    }) => {
        const mapLoaded = !!mapRef.current;

        const hasService = !!service?.id;

        const hasToFitServiceRouteBoundsOnMap = [hasPlaces, mapLoaded, hasService].every(Boolean);

        if (hasToFitServiceRouteBoundsOnMap) {
            setEndPlace(destination);

            const vehicleLastPoints = lastPoints?.filter?.(hasLatLng) || [];

            const [currentPlaceAddress] = currentPlace.addresses || [];

            const latAndLngToFitBounds = [
                currentPlaceAddress,
                destination,
                ...vehicleLastPoints,
            ].filter(hasLatLng);

            const hasBounds = latAndLngToFitBounds.length > 0;

            if (!hasBounds) return;

            fitBoundsOnMap(latAndLngToFitBounds);
            setFitBounds(true)
        }
    }

    const formatDuration = seconds => {
        const date = new Date();

        const time = addSeconds(new Date(date.getFullYear(), date.getMonth(), date.getDay(), 0, 0, 0, 0), seconds);

        const formattedTime = format(new Date(time), "HH,mm");

        const [hours, minutes] = formattedTime.split(",");

        if (+hours === 0) return `${minutes}min`;

        return `${hours}h ${minutes}min`;
    };

    useEffect(() => {
        // Route between user and destination and map bound

        const hasOrderedPlaces = orderedPlaces.length > 0;

        const hasCurrentPlace = !!currentPlace.id;

        const hasPlaces = places?.length > 0;

        if (hasOrderedPlaces && hasCurrentPlace && hasPlaces) {
            const route = {
                origin: {},
                destination: {},
                waypoints: {},
            };

            const {
                destination,
            } = searchRouteBetweenUserAndDestiny({
                currentPlace,
                orderedPlaces
            });

            route.destination = destination;

            if (!hasFitBounds) fitBounds({
                hasPlaces,
                orderedPlaces,
                currentPlace,
                destination: route.destination
            });
        }
        // eslint-disable-next-line
    }, [orderedPlaces, currentPlace])

    useEffect(() => {
        // Route between vehicle and user

        const hasPoints = lastPoints?.length > 0;

        const hasCurrentPlace = !!currentPlace.id;

        if (hasPoints && hasCurrentPlace) searchRouteBetweenVehicleAndUser({
            currentPlace
        });
        // eslint-disable-next-line
    }, [lastPoints?.length, currentPlace, vehiclePosition]);

    useEffect(() => {
        // Map otimization routine
        const hasRouteConfiguration = !!routeConfiguration.currentPlace;

        if (hasRouteConfiguration) {
            const [
                isOrderedPlacesEqual,
                isCurrentPlaceEqual
            ] = [
                    JSON.stringify(routeConfiguration.orderedPlaces) === JSON.stringify(orderedPlaces) && !!orderedPlaces.length,
                    JSON.stringify(routeConfiguration.currentPlace) === JSON.stringify(currentPlace) && !!currentPlace.id
                ];

            if (!isOrderedPlacesEqual) setOrderedPlaces(routeConfiguration.orderedPlaces);

            if (!isCurrentPlaceEqual) setCurrentPlace(routeConfiguration.currentPlace);

            const [vehicle] = lastPoints;

            const latIsDifferent = vehicle?.lat && (vehicle?.lat !== vehiclePosition.lat);

            const lngIsDifferent = vehicle?.lng && (vehicle?.lng !== vehiclePosition.lng);

            const vehicleHasMoved = latIsDifferent && lngIsDifferent;

            if (vehicleHasMoved) setVehiclePosition({
                lat: vehicle.lat,
                lng: vehicle.lng,
            });

        }
        // eslint-disable-next-line
    }, [lastPoints, servicePlaces, places, routeConfiguration]);

    useEffect(() => {
        // Redirection routine after service has ended

        const hasServiceType = !!service.type;

        if (hasEndPosition && hasVehiclePosition && hasServiceType) {

            const hasToRedirect = verifyIfHasToRedirectToEvaluation({
                vehiclePosition,
                endPlace,
                service,
            });

            if (hasToRedirect) history.push(LOGISTICS_SERVICES + "/" + service.id + EVALUATION);

        }
        // eslint-disable-next-line
    }, [vehiclePosition, endPlace, hasVehiclePosition, hasEndPosition]);

    useEffect(() => {
        // set exceptions (if valid) to places addresses

        const hasPlaces = places?.length > 0;

        const serviceHasPlaces = servicePlaces?.length > 0;

        if (hasPlaces && !serviceHasPlaces) {
            dispatch(setPlacesToService({
                places,
                service,
                user,
            }))
        }

        // eslint-disable-next-line
    }, [places, servicePlaces])

    useEffect(() => {

        const serviceHasPlaces = service?.places?.length;

        if (serviceHasPlaces) setRouteConfiguration(getRouteConfiguration())

        // eslint-disable-next-line
    }, [service?.places])

    const serviceRouteMarkerName = useMemo(
        () => {
            if (serviceRoute.duration && serviceRoute.distance) {

                const formattedDuration = formatDuration(serviceRoute.duration)

                return formattedDuration + " " + localizedStrings.logisticsServices.OfDistance
            }

            return ""
        },
        [serviceRoute]
    );

    const vehicleRouteMarkerName = useMemo(
        () => {
            if (vehicleRoute.duration && vehicleRoute.distance) {

                const formattedDuration = formatDuration(vehicleRoute.duration)

                return formattedDuration + " " + localizedStrings.logisticsServices.OfDistance
            };

            const [vehicle] = lastPoints || [];

            const vehicleModel = vehicle?.vehicle?.model;

            return vehicleModel
        },
        [vehicleRoute, lastPoints]
    );

    const hasEmbarked = useMemo(
        () => {
            const {
                embarked
            } = verifyIfUserProgressStatus({
                service,
                userId: user?.id
            });

            return embarked;
        },
        [service, user]
    );

    const hasArrived = useMemo(
        () => {
            const {
                arrived
            } = verifyIfUserProgressStatus({
                service,
                userId: user?.id
            });

            return arrived;
        },
        [service, user]
    )

    return (
        <Map
            mapElementStyle={{ minHeight: "100%" }}
            onMapLoad={({ map }) => {
                mapRef.current = map
                map.setZoom(4);
                const brazilCoords = {
                    lat: -14.760824585367107,
                    lng: -54.98130527770911
                }
                map.setCenter(brazilCoords)
            }}
        >

            {
                hasVehiclePosition && !hasArrived &&
                <VehicleMarker
                    showVehicleIcon
                    showVehicleName={!hasEmbarked}
                    name={vehicleRouteMarkerName}
                    location={vehiclePosition}
                />
            }
            {
                currentPlace?.addresses?.length && !hasEmbarked && !hasArrived &&
                <PersonMarker
                    showVehicleIcon
                    showVehicleName
                    iconOptions={{ marginLeft: "6px", top: "5px", left: "7px" }}
                    optionsIcons={{ height: "30px", width: "30px" }}
                    location={currentPlace?.addresses?.slice?.()?.shift?.()}
                />
            }
            {
                hasEndPosition &&
                <DestinationMarker
                    showVehicleIcon
                    showVehicleName
                    iconOptions={{ marginLeft: "6px", top: "5px", left: "7px" }}
                    optionsIcons={{ height: "30px", width: "30px" }}
                    location={endPlace}
                    name={serviceRouteMarkerName}
                />
            }
            {
                serviceRoute?.coordinates?.length > 0 &&
                <Polyline
                    path={serviceRoute.coordinates}
                    options={{
                        fillColor: "transparent",
                        strokeColor: '#0057FF',
                        strokeOpacity: 0.7,
                        strokeWeight: 5,
                    }}
                />
            }
            {
                vehicleRoute?.coordinates?.length > 0 && !hasEmbarked && !hasArrived &&
                <Polyline
                    path={vehicleRoute.coordinates}
                    options={{
                        fillColor: "transparent",
                        strokeColor: '#955DFC',
                        strokeOpacity: 0.9,
                        strokeWeight: 5,
                    }}
                />
            }
        </Map>
    )
}
