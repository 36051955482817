import React, { memo } from 'react';
import { VehiclePin, ErrorBoundary, } from 'components';
import { Marker, InfoWindow } from '@react-google-maps/api'
import { localizedStrings } from 'constants/localizedStrings';
export default memo(({
    location,
    showVehicleIcon = false,
    showVehicleName,
    clusterer,
    ...pinOptions
}) => {
    return (
        <ErrorBoundary>
            <Marker
                title={location?.name}
                clickable={false}
                icon={showVehicleIcon && {
                    url: ""
                }}
                draggable={false}
                clusterer={clusterer}
                position={{ lat: location?.lat, lng: location?.lng }}
            >
                {
                    showVehicleIcon &&
                    <InfoWindow>
                        <VehiclePin
                            icon={"female_solid"}
                            showVehicleName={showVehicleName}
                            name={localizedStrings.logisticsServices.departurePlace}
                            cursor={"pointer"}
                            backgroundColor={"#fff"}
                            borderColor={"#000"}
                            iconColor={"#000"}
                            textOptions={true}
                            position={"relative"}
                            {...pinOptions}
                        />
                    </InfoWindow>
                }
            </Marker>
        </ErrorBoundary>
    )
})