import {
  USER_LOGIN_SUCCESS,
  USER_SESSION_UPDATE_SUCCESS,
  USER_CHANGE_OPERATION_STATES
} from "./reducer";
import storage from "redux-persist/lib/storage";
import { api } from "services/api";
import qs from "qs";
import { loadOrganization } from "store/modules";
import { toast } from "react-toastify";
import { localizedStrings } from "constants/localizedStrings";
import { HTTP_STATUS } from "constants/environment";

export function userChangeOperationStates({
  loginSuccess = true,
  loginFail = false,
  loginLoading = false,
  logoutSuccess = true,
  logoutFail = false,
  logoutLoading = false,
  forgotFail = false,
  forgotSuccess = false,
  forgotLoading = false,
  recoverLoading = false,
  recoverSuccess = false,
  recoverFail = false,
}) {
  return {
    type: USER_CHANGE_OPERATION_STATES,
    payload: {
      loginSuccess,
      loginFail,
      loginLoading,
      logoutSuccess,
      logoutFail,
      logoutLoading,
      forgotFail,
      forgotSuccess,
      forgotLoading,
      recoverLoading,
      recoverSuccess,
      recoverFail,
    }
  };
}
export function fetchLoginSuccess({ user }) {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: {
      user
    }
  };
}
export function updateUserInSession(user) {
  return {
    type: USER_SESSION_UPDATE_SUCCESS,
    payload: {
      user
    }
  };
}

export function fetchLogoutSuccess() {
  return {
    type: "RESET_STATE",
  };
}

export const forgotPassword = ({
  email
}) => async dispatch => {
  dispatch(userChangeOperationStates({ forgotLoading: true }))
  try {

    const URL = "/forgot/v1";
    const params = {
      forgot: {
        email,
      },
    };

    const {
      status
    } = await api.put(URL, qs.stringify(params));
    // eslint-disable-next-line 
    if (status !== HTTP_STATUS.SUCCESS) throw 'error: forgot password';

    dispatch(userChangeOperationStates({ forgotSuccess: true }));


  } catch (error) {
    dispatch(userChangeOperationStates({ forgotFail: true }))
  }
};
export const recoverPassword = ({
  password,
  token,
  email,
}) => async dispatch => {
  dispatch(userChangeOperationStates({ recoverLoading: true }))
  try {

    const URL = "/user/v1/recovery-password";
    const params = {
      user: {
        email,
        token,
        password,
      },
    };

    const {
      status
    } = await api.put(URL, qs.stringify(params),
      {
        headers: {
          Authorization: "Bearer " + token
        }
      });
    // eslint-disable-next-line 
    if (status !== HTTP_STATUS.SUCCESS) throw 'error: recover password';

    toast.success(localizedStrings.passwordChangedSuccessfully);

    dispatch(userChangeOperationStates({ recoverSuccess: true }));

  } catch (error) {
    dispatch(userChangeOperationStates({ recoverFail: true }))
  }
};
export const login = (data) => async (dispatch) => {
  dispatch(userChangeOperationStates({ loginLoading: true }))
  try {
    const { identification, password } = data;

    const URL = "/login/v1/place";
    const params = {
      place: {
        identification,
        code: password,
      },
    };

    const {
      data: { place: placeObj }
    } = await api.post(URL, qs.stringify(params));

    const {
      place,
      address,
      partners,
      token,
    } = placeObj

    await storage.setItem("@token", JSON.stringify({ token }));

    place?.organization_id && await dispatch(loadOrganization({
      organization_id: place?.organization_id
    }));

    dispatch(fetchLoginSuccess({
      user: {
        token,
        id: place?.id,
        organization_id: place?.organization_id,
        client_id: place?.client_id,
        code: place?.code,
        email: place?.email,
        external_id: place?.external_id,
        identification: place?.identification,
        name: place?.name,
        phone: place?.phone,
        place_id: place?.place_id,
        status: place?.status,
        status_code: place?.status_code,
        address: {
          address1: address?.address1,
          address2: address?.address2,
          city: address?.city,
          id: address?.id,
          lat: address?.lat,
          lng: address?.lng,
          neighborhood: address?.neighborhood,
          number: address?.number,
          place_id: address?.place_id,
          state: address?.state,
          zipcode: address?.zipcode,
        },
        exceptionsPerService: {},
        partners,
      }
    }));
    dispatch(userChangeOperationStates({ loginSuccess: true }));


  } catch (error) {
    console.log(error);
    dispatch(userChangeOperationStates({ loginFail: true }))
  }
};

export const logout = data => async dispatch => {
  try {
    dispatch(fetchLogoutSuccess());
    dispatch(userChangeOperationStates({ logoutSuccess: true }));
  } catch (error) {
    dispatch(fetchLogoutSuccess());
    dispatch(userChangeOperationStates({ logoutFail: true }));
  }
};

export const updatePlaceExceptionPerService = data => async dispatch => {
  try {
    const {
      serviceId,
      user,
      exceptions = [],
    } = data;

    const newUser = {
      ...user,
    }

    const hasExceptionInThisService = newUser.exceptionsPerService[serviceId]?.length;

    if (!hasExceptionInThisService) newUser.exceptionsPerService[serviceId] = exceptions

    dispatch(updateUserInSession(newUser));

  } catch (error) {
    console.log(error);
  }
};
