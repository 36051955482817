import styled from 'styled-components';

export const DepartureIdentificationStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    padding: "24px 0",
    alignItems: "center",
    borderBottom: "1px solid #DDDDDD",
    "& img": {
        minHeight: "220px",
        padding: "10px 0 0 0",
    }
}))