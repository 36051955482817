import {
    CALENDAR_CONFIGURATION
} from './reducer';


export function setCalendarConfiguration(configutation) {
    return {
        type: CALENDAR_CONFIGURATION,
        payload: configutation
    };
}

