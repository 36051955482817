
import {
    SET_BEST_ROUTE_VEHICLE,
    SET_BEST_ROUTE_SERVICE,
    SET_BEST_ROUTE
} from './reducer';
import { proxyApi, } from 'services/api';
import { API_KEY_MAP_DIRECTIONS, } from 'constants/environment.js'


export function setBestRoute({ coordinates, start_location, end_location, waypoint_order, legs, }) {
    return {
        type: SET_BEST_ROUTE,
        payload: {
            coordinates,
            start_location,
            end_location,
            waypoint_order,
            legs
        }
    };
}
export function setBestRouteVehicle({
    coordinates,
    start_location,
    end_location,
    waypoint_order,
    legs,
    distance,
    duration,
}) {
    return {
        type: SET_BEST_ROUTE_VEHICLE,
        payload: {
            coordinates,
            start_location,
            end_location,
            waypoint_order,
            legs,
            distance,
            duration,
        }
    };
}

export function setBestRouteService({
    coordinates,
    start_location,
    end_location,
    waypoint_order,
    legs,
    distance,
    duration,
}) {
    return {
        type: SET_BEST_ROUTE_SERVICE,
        payload: {
            coordinates,
            start_location,
            end_location,
            waypoint_order,
            legs,
            distance,
            duration,
        }
    };
}

const filterCoordinates = coordinate => !!coordinate?.length;

const extractCorrectLatAndLng = (arrayOfCoordinates = []) => {
    arrayOfCoordinates.reverse();
    const [lat, lng] = arrayOfCoordinates;
    return { lat, lng };
}

const getRoute = async ({
    waypoints,
    latLng,
}) => {
    const URL = "/api/directions/json?key=" + API_KEY_MAP_DIRECTIONS;
    try {

        const directionsParams = {
            "geometries": "geojson",
            "travelMode": window?.google?.maps?.TravelMode?.DRIVING,
            "origin": "" + [latLng.origin?.lat, latLng.origin?.lng],
            waypoints,
            "destination": "" + [latLng.destination?.lat, latLng.destination?.lng],
            "optimize": true
        }

        const {
            data
        } = await proxyApi.get(URL, { params: directionsParams });

        const { routes: [route] } = data;

        const { geometry: { coordinates = [] }, legs: [info], waypoint_order = [] } = route;

        const { start_location, end_location } = info;

        const summary = route.legs.reduce((routeSummary, leg) => {

            const { duration, distance } = leg;

            const legSummary = {
                duration: duration.value,
                distance: distance.value,
            }
            routeSummary.push(legSummary);
            return routeSummary;
        }, []);

        const distance = summary.reduce((total, currentDistance) => total + currentDistance.distance, 0);
        const duration = summary.reduce((total, currentDuration) => total + currentDuration.duration, 0);

        return {
            start_location: extractCorrectLatAndLng(start_location?.lat),
            end_location: extractCorrectLatAndLng(end_location?.lat),
            coordinates: coordinates.filter(filterCoordinates).map(extractCorrectLatAndLng),
            waypoint_order,
            legs: route.legs,
            distance,
            duration,
        }

    } catch (error) {
        return {
            start_location: {},
            end_location: {},
            coordinates: [],
            waypoint_order: [],
            legs: [],
            distance: 0,
            duration: 0,
        }
    }
}
export const getBestRouteVehicle = ({
    latLng = {},
    waypoints = "",
}) => async dispatch => {
    try {

        const {
            start_location,
            end_location,
            coordinates,
            waypoint_order,
            legs,
            distance,
            duration,
        } = await getRoute({ latLng, waypoints });

        dispatch(setBestRouteVehicle({
            start_location,
            end_location,
            coordinates,
            waypoint_order,
            legs,
            distance,
            duration,
        }))
    } catch (error) {
        console.log('[error] get best route: ' + error);
    }
};
export const getBestRouteService = ({
    latLng = {},
    waypoints = "",
}) => async dispatch => {
    try {
        const {
            start_location,
            end_location,
            coordinates,
            waypoint_order,
            legs,
            distance,
            duration,
        } = await getRoute({ latLng, waypoints });

        dispatch(setBestRouteService({
            start_location,
            end_location,
            coordinates,
            waypoint_order,
            legs,
            distance,
            duration,
        }))
    } catch (error) {
        console.log('[error] get best route: ' + error);
    }
};
export const getBestRoute = ({
    latLng = {},
    waypoints = "",
}) => async dispatch => {
    try {
        const {
            start_location,
            end_location,
            coordinates,
            waypoint_order,
            legs,
        } = await getRoute({ latLng, waypoints });

        dispatch(setBestRoute({
            start_location,
            end_location,
            coordinates,
            waypoint_order,
            legs
        }))
    } catch (error) {
        console.log('[error] get best route: ' + error);
    }
};