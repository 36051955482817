import React, { memo } from "react";
import {
    Link,
    Icon,
    Text,
} from "components";
import {
    TitleWrapper
} from './style';
import { localizedStrings } from "constants/localizedStrings";
import { useHistory } from "react-router-dom";

export default memo(function NotificationHeader({
    children,
    contentStyle,
}) {

    const history = useHistory();

    return (
        <>
            <div>
                <Link
                    padding="8px 12px 12px 12px"
                    href={"#"}
                    onClick={() => {
                        history.goBack()
                    }}
                    position="absolute"
                    left="10px"
                >
                    <Icon
                        icon={"arrow"}
                        width="10px"
                        height="10px"
                        color="#192366"
                        divProps={{
                            transform: "rotate(90deg) scale(1)"
                        }}
                    />
                </Link>
            </div>
            <TitleWrapper >
                <Text
                    fontWeight="bold"
                    fontSize="15px"
                    color="#505050"
                >
                    {localizedStrings.notifications.notificationCenter}
                </Text>
            </TitleWrapper>
        </>
    );
})