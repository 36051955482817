import React, { useState } from 'react'
import { localizedStrings } from "constants/localizedStrings";
import { useSelector } from "react-redux";
import { CardInput, Button, Text, } from "components";
import {
    LoginActionButtons
} from "./style.js";
export default function LoginForm({
    onSubmit,
    errors,
    inputsConfig,
}) {
    const {
        loginLoading,
    } = useSelector(state => state.auth);

    const [passwordVisibility, setPasswordVisibility,] = useState(false);

    return (
        <form>
            <Text
                fontSize="22px"
                color="#505050"
                margin="20px 0px"
                white-space="normal"
                font-weight="500"
                font-style="normal"
                textAlign="center"
            >
                {localizedStrings.login.welcome}
            </Text>
            <Text
                fontSize="13px"
                color="#838383"
                white-space="normal"
                font-weight="500"
                font-style="normal"
                textAlign="center"
                marginBottom="20px"
                padding="0 15px"
            >
                {localizedStrings.login.loginInstructions}
            </Text>
            <CardInput
                padding="5px 0"
                onChange={inputsConfig.onChange}
                register={inputsConfig.register}
                inputs={[
                    {
                        label: localizedStrings.login.typeIdentification,
                        defaultValue: inputsConfig.getValues()?.identification,
                        required: true,
                        name: "identification",
                        type: "custom",
                        noMask: true,
                        error: errors.identification.error,
                        errorText: errors.identification.message,
                        placeholder: localizedStrings.login.typeAnyIdentification,
                    },
                ]}
            />
            <CardInput
                padding="5px 0"
                onChange={inputsConfig.onChange}
                register={inputsConfig.register}
                inputs={[
                    {
                        label: localizedStrings.login.accessKey,
                        defaultValue: inputsConfig.getValues()?.password,
                        name: "password",
                        type: passwordVisibility ? "text" : "password",
                        error: errors.password.error || errors.emailPasswordWrong.error,
                        errorTitle:
                            (errors.password.error && errors.password.message)
                            ||
                            (errors.emailPasswordWrong.error && errors.emailPasswordWrong.message),
                        maxLength: 6,
                        required: true,
                        noMask: true,
                        iconOptions: {
                            icon: passwordVisibility ? "eye" : "eye-closed",
                            onClick: () => setPasswordVisibility(visibility => !visibility),
                            width: passwordVisibility ? "12px" : "15px",
                            height: passwordVisibility ? "12px" : "15px",
                        },
                        placeholder: localizedStrings.login.typeAccessKey,
                    },
                ]}
            />
            <LoginActionButtons
                margin="22px 0"
            >
                <Button
                    hasLoad
                    backgroundColor={loginLoading ? false : "#192379"}
                    color="#fff"
                    width="123px"
                    height="36px"
                    minWidth="41px"
                    flex="1"
                    padding="0"
                    onClick={onSubmit}
                    title={localizedStrings.login.searchForServices}
                    textConfig={{
                        fontWeight: '500',
                        fontSize: "13px",
                        color: "#fff"
                    }}
                    loading={loginLoading}
                    disabled={loginLoading}
                />
            </LoginActionButtons>
        </form>
    )
}

