import styled from 'styled-components';

export const LoadingSpinStyle = styled.div(props => ({
    border: `${props.weight || "8px"} solid ${props.backColor || "#fff"}`,
    borderRadius: "50%",
    borderTop: `${props.weight || "8px"} solid ${props.frontColor || "#1a2565"}`,
    borderLeft: props.halfColored && `${props.weight || "8px"} solid ${props.frontColor || "#1a2565"}`,
    width: props.size || "50px",
    height: props.size || "50px",
    animation: "spin 2s linear infinite",
    ...props,
}))
