import styled from 'styled-components';

export const NotificationWrapper = styled.div(props => ({
    background: "transparent",
    margin: "10px 0",
    "&>div:not(.notificationDate)": {
        boxShadow: "0px 0px 7px #00000030",
    },
    ...props
}))
export const NotificationItemStyle = styled.div(props => ({
    textDecoration: "none !important",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid #1D1B8426",
    borderLeft: props.active && "6px solid #0795FA",
    background: "#fff",
    padding: "8px",
    flex: "1",
    borderRadius: "6px",
    ...props
}))
export const NotificationIconDiv = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 17px 12px 12px",
    marginRight: "10px",
    border: "1px solid #1D1B8426",
    background: "#fff",
    borderRadius: "6px",
    ...props
}))
export const NotificationContent = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: "1",
    ...props
}))
export const NotificationLink = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...props
}))