import styled from 'styled-components';

export const EvaluationThankWrapper = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 0px 13px rgba(49, 48, 99, 0.08)",
    borderRadius: "10px",
    ["@media" + props.theme.device.mobileS]: {
        ".evaluation-stars>span": {
            fontSize: "40px !important"
        }
    }
}))
export const EvaluationContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    flex: "1",
    padding: "20px 0",
    borderBottom: "1px solid #DDDDDD",
    "&:last-child": {
        borderBottom: "none",
    },
    "&>div": props.child,
    ...props,
}))

export const EvaluationContent = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    ...props,
}))
