import React from "react";
import {
    EvaluationForm
} from "components";
import {
    UserServiceWrapper,
} from './style';

export default function UserEvaluation() {

    return (
        <UserServiceWrapper padding="20px">
            <EvaluationForm />
        </UserServiceWrapper>
    )
}
