import React, { useState } from 'react';

import CalendarHeader from './calendarHeader';
import OverviewHeader from './overviewHeader';
import DetailsHeader from './detailsHeader';
import NotificationHeader from './notificationHeader';
import RouteHeader from './routeHeader';

import {
    Card,
    CardContent,
    CardTitle,
    PageBackground,
} from "components";

export default function Header({
    type = "overview",
    children,
    contentStyle,
    ...options
}) {

    const [headerTypes,] = useState({
        calendar: <CalendarHeader />,
        overview: <OverviewHeader />,
        details: <DetailsHeader />,
        evaluation: <DetailsHeader />,
        notification: <NotificationHeader />,
        route: <RouteHeader />,
    });

    return (
        <PageBackground>
            <Card>
                <CardTitle
                    flexDirection="row"
                    justifyContent="center"
                    padding="16px"
                    position="relative"
                >
                    {
                        headerTypes[type]
                    }
                </CardTitle>
                <CardContent {...contentStyle}>
                    {children}
                </CardContent>
            </Card>
        </PageBackground>
    )
}
