import styled from 'styled-components';

export const WeekStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    minHeight: props.numberOfweeks ? "70px" : `calc(80% / ${props.numberOfweeks || 1})`,
    "&>div": {
        minWidth: "calc(100% /7)",
        maxWidth: "calc(100% /7)",
    }
}));