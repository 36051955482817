import { getMonth } from "date-fns";

export const CALENDAR_CONFIGURATION = "calendar_configuration";

const INITIAL_STATE = {
  type: "month" || "week",
  servicesToShow: {},
  month: getMonth(new Date()),
  week: 0,
  date: new Date()
};

export default function notifications(state = INITIAL_STATE, action) {
  const actionTypes = {
    calendar_configuration() {
      return {
        ...state,
        ...action.payload
      };
    },
  };

  if (actionTypes[action.type]) return actionTypes[action.type]();
  return state;
}
