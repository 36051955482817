import styled from "styled-components";

export const ImageWrapper = styled.div(props => ({
    display: "flex",
    flex: "1",
    justifyContent: "center",
    "& img": {
        maxHeight: "32px",
    },
    ["@media" + props.theme.device.laptopM]: {
        justifyContent: "center",
        "& img": {
            maxHeight: "25px"
        },
    },
}));
