import React from 'react'
import { NotificationItem } from 'components';

export default function NotificationsList({
    notifications
}) {

    return (
        notifications.map((notification, index) => (
            <NotificationItem
                key={index}
                notification={notification}
            />
        ))
    )
}
