import React, { useMemo } from 'react'
import Week from '../week';
import {
    MonthStyle
} from './style';
import { getWeeksInMonth, startOfMonth, isDate, getYear, getMonth } from 'date-fns';
import { useSelector } from 'react-redux';

export default function Month() {

    const {
        date
    } = useSelector(state => state.calendar);

    const {
        serviceByDate
    } = useSelector(state => state.services);

    const selectedDate = useMemo(
        () => {
            try {

                if (!isDate(date)) throw new Error("The given date is an invalid date");

                return new Date(date)
            } catch (error) {
                return new Date();
            }
        },
        [date]
    );

    const startOfCurrentMonth = useMemo(
        () => startOfMonth(selectedDate),
        [selectedDate]
    );

    const numberOfweeks = useMemo(
        () => getWeeksInMonth(selectedDate),
        [selectedDate]
    );

    const numberOfWeeks = useMemo(
        () => {
            const weeksCountList = [];

            if (isNaN(numberOfweeks)) return [];

            weeksCountList.length = numberOfweeks;

            return Array.from(weeksCountList)
        },
        [numberOfweeks]
    );

    const calendarYear = useMemo(
        () => getYear(date),
        [date],
    )
    const calendarMonth = useMemo(
        () => getMonth(date) + 1,
        [date],
    )

    const filterByMonth = serviceByDateInCurrentYear => {
        const servicesRanges = Object.keys(serviceByDateInCurrentYear).filter(serviceMonth => serviceMonth.match(calendarMonth))

        const monthServices = servicesRanges
            .map(monthRange => serviceByDateInCurrentYear[monthRange])
            .flat();

        const hasServicesInMonth = servicesRanges.length > 0;

        if (hasServicesInMonth) return monthServices;

        return false;
    };

    const filterByYear = serviceByDate => {
        const [key, value] = [0, 1];
        const hasServiceInCurrentYear = !!serviceByDate[key].match(calendarYear);

        if (hasServiceInCurrentYear) return serviceByDate[value];

        return false;
    }

    const servicesFromCurrentMonth = useMemo(() => {
        if (!serviceByDate) return [];

        const hasServicesInCalendarYear = Object.keys(serviceByDate).some(yearRange => yearRange.match(calendarYear));

        if (!hasServicesInCalendarYear) return;

        const servicesInThisMonth = Object.entries(serviceByDate)
            .map(filterByYear)
            .map(filterByMonth)
            .filter(Array.isArray)
            .flat()

        return servicesInThisMonth;
        // eslint-disable-next-line
    }, [serviceByDate]);

    return (
        <MonthStyle>
            {
                numberOfWeeks.map((week, index) => (
                    <Week
                        key={index}
                        weekIndex={index + 1}
                        startOfCurrentMonth={startOfCurrentMonth}
                        numberOfweeks={numberOfweeks}
                        servicesInMonth={servicesFromCurrentMonth}
                    />))
            }
        </MonthStyle>
    )
}
