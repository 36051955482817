import React from 'react'
import { List } from "react-virtualized";
import { ServiceItem } from 'components';
import { useSelector } from 'react-redux';
const [
    height,
    width,
] = [
        window.outerHeight,
        window.outerWidth,
    ];

const bottomHeightOffset = (
    (height > 830 && 415) ||
    (height < 830 && 295)
);

export default function ServicesList() {

    const {
        services
    } = useSelector(state => state.services)

    return (
        <List
            width={width}
            rowCount={+services?.length}
            height={height - bottomHeightOffset}
            rowHeight={70}
            style={{
                padding: "0px",
                width: "100%",
                maxHeight: '98%',
                flexDirection: 'row'
            }}
            rowRenderer={({
                key, // Unique key within array of rows
                index, // Index of row within collection
                style, // Style object to be applied to row (to position it)
            }) => (
                    <ServiceItem
                        style={style}
                        key={key}
                        service={services[index]}
                    />
                )}
        />
    )
}
