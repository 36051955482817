import React from "react";
import {
    Text,
    Link,
} from "components";
import { localizedStrings } from "constants/localizedStrings";
import {
    NotificationsList,
    EmptyStateNotification
} from "containers";
import {
    UserNotificationsWrapper
} from './style'
import { useSelector, useDispatch } from 'react-redux';
import { updateNotification } from "store/modules";

export default function UserNotifications() {
    const dispatch = useDispatch();

    const {
        notifications
    } = useSelector(state => state.notifications);

    const clearNotifications = () => {
        return notifications.map(n => {
            const notificationId = n.id;

            dispatch(updateNotification({
                id: notificationId,
            }));

            return notificationId
        });
    }

    return (
        <UserNotificationsWrapper>
            {
                !Array.isArray(notifications) || notifications.length === 0
                    ? <EmptyStateNotification />
                    : (<>
                        <NotificationsList notifications={notifications} />
                        <Link
                            padding="10px"
                            textAlign="center"
                            textTransform="uppercase"
                            border="1px solid #1a2565"
                            margin="25px 0 10px 0"
                            borderRadius="6px"
                            display="flex"
                            textDecoration="none"
                            flexDirection="row"
                            justifyContent="center"
                            as="button"
                            onClick={(e) => {
                                clearNotifications()
                            }}
                        >
                            <Text
                                as="div"
                                display="flex"
                                alignItems="center"
                                fontSize="14px"
                                color="#1a2565"
                                fontWeight="bold"
                            >
                                {localizedStrings.notifications.cleanNotifications}
                            </Text>
                        </Link>
                    </>)
            }
        </UserNotificationsWrapper>
    )
}
