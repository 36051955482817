import styled from 'styled-components';

export const CalendarButtonsStyle = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "18px 0 0 0",
    padding: "13px 0",
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100%",
    background: "#fff",
    border: "5px solid #cccccc60",
    borderWidth: "1px 0 0 0",
    "& p:first-letter": {
        textTransform: "capitalize"
    }
}));