import React from "react";
import {
    Text,
    Link,
    Icon,
    ScreenLoading,
} from "components";
import { localizedStrings } from "constants/localizedStrings";
import {
    EmptyStateOverview,
    ServicesList,
} from "containers";
import {
    UserServiceWrapper,
} from './style'
import { LOGISTICS_SERVICES_CALENDAR_PATH } from "constants/paths";
import { getMonth, getYear } from "date-fns";
import { useSelector } from "react-redux";

export default function UserServices() {

    const {
        services,
        loadSuccess,
        loadLoading,
    } = useSelector(state => state.services)

    return (
        <UserServiceWrapper>
            <div>
                <Text
                    fontSize="16px"
                    color="#505050"
                >
                    {localizedStrings.logisticsServices.servicesListedBelow}
                </Text>
            </div>
            {
                loadLoading &&
                <ScreenLoading
                    loading={loadLoading}
                />
            }
            {
                services?.length === 0 && loadSuccess &&
                <EmptyStateOverview />
            }
            {
                services?.length > 0 && loadSuccess &&
                <>
                    <ServicesList />
                    <Link
                        padding="10px"
                        textAlign="center"
                        textTransform="uppercase"
                        border="1px solid #1a2565"
                        margin="25px 0 10px 0"
                        borderRadius="6px"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        // eslint-disable-next-line 
                        href={LOGISTICS_SERVICES_CALENDAR_PATH + "/" + +getYear(new Date()) + "/" + `${getMonth(new Date()) + 1}`}
                    >
                        <Icon
                            icon="calendar"
                            width="15px"
                            height="15px"
                            divProps={{
                                padding: "0 10px 2px 0"
                            }}
                        />
                        <Text
                            as="div"
                            display="flex"
                            alignItems="center"
                            fontSize="14px"
                            color="#1a2565"
                            fontWeight="bold"
                        >
                            {localizedStrings.logisticsServices.calendar}
                        </Text>
                    </Link>
                </>
            }
        </UserServiceWrapper>
    )
}
