export default {
    days: {
        sun: "D",
        mon: "S",
        tue: "T",
        wed: "Q",
        thu: "Q",
        fri: "S",
        sat: "S",
    }
}