import React, { useState, } from 'react'
import {
    CalendarButtonsStyle
} from './style'
import { Icon, Link, Text } from 'components'
import { LOGISTICS_SERVICES_CALENDAR_PATH } from 'constants/paths'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/esm/locale';

export default function MonthsPaginator({
    month,
    year,
}) {

    const [monthConfiguration,] = useState({
        previousMonth: +month - 1,
        previousMonthName: format(new Date(+year, +month - 1), "MMMM", {
            locale: ptBR
        }),
        month,
        nextMonth: +month + 1,
        nextMonthName: format(new Date(+year, +month + 1), "MMMM", {
            locale: ptBR
        }),
    });


    const getYearIndex = (monthIndex) => {

        const yearConditions = {
            isPreviousYear: monthIndex < 1,
            isNextYear: monthIndex >= 12,
        };

        if (yearConditions.isPreviousYear) return +year - 1;

        if (yearConditions.isNextYear) return +year + 1;

        return +year
    };

    const getMonthIndex = (monthIndex) => {

        const monthConditions = {
            isPreviousYear: +monthIndex < 1,
            isNextYear: +monthIndex >= 12,
        };

        if (monthConditions.isPreviousYear) return 12;

        if (monthConditions.isNextYear) return 1;

        return +monthIndex + 1
    };


    return (
        <CalendarButtonsStyle>
            <Link
                display="flex"
                alignItems="center"
                justifyContent="center"
                href={LOGISTICS_SERVICES_CALENDAR_PATH + "/" + getYearIndex(monthConfiguration.previousMonth) + "/" + getMonthIndex(monthConfiguration.previousMonth)}
            >
                <Icon
                    icon="arrow"
                    width="12px"
                    height="12px"
                    color="#3B3992"
                    divProps={{
                        transform: "rotate(90deg) scale(1)",
                        padding: "16px",
                        borderRadius: "35px",
                        backgroundCOlor: "#fff",
                        boxShadow: "0px 0px 4px #00000030",
                        width: "50px",
                        height: "50px",
                        paddingLeft: "17px",
                    }}
                />
                <Text
                    padding="0 12px"
                >
                    {monthConfiguration.previousMonthName}
                </Text>
            </Link>
            <Link
                display="flex"
                alignItems="center"
                justifyContent="center"
                href={LOGISTICS_SERVICES_CALENDAR_PATH + "/" + getYearIndex(monthConfiguration.nextMonth) + "/" + getMonthIndex(monthConfiguration.nextMonth)}
            >
                <Text
                    padding="0 12px"
                >
                    {monthConfiguration.nextMonthName}
                </Text>
                <Icon
                    icon="arrow"
                    width="12px"
                    height="12px"
                    color="#3B3992"
                    divProps={{
                        transform: "rotate(-90deg) scale(1)",
                        padding: "16px",
                        borderRadius: "35px",
                        backgroundCOlor: "#fff",
                        boxShadow: "0px 0px 4px #00000030",
                        width: "50px",
                        height: "50px",
                        paddingLeft: "17px",
                    }}
                />
            </Link>
        </CalendarButtonsStyle>
    )
}
