import styled from 'styled-components';

export const ServiceWrapper = styled.div(props => ({
    background: "transparent",
    ...props
}))
export const ServiceItemStyle = styled.div(props => ({
    textDecoration: "none !important",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid #1D1B8426",
    background: "#fff",
    padding: "8px",
    flex: "1",
    borderRadius: "6px",
    ...props
}))
export const ServiceIconDiv = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 17px 12px 12px",
    marginRight: "10px",
    border: "1px solid #1D1B8426",
    background: "#fff",
    borderRadius: "6px",
    ...props
}))
export const ServiceContent = styled.div(props => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: "1",
    ...props
}))
export const ServiceLink = styled.div(props => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...props
}))