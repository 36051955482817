import React, { useEffect } from "react";
import {
    Header,
    UserServiceRoute,
} from "containers";

import { useDispatch, useSelector } from "react-redux";

import { loadOneService, loadMapVehicles, loadPlaces } from "store/modules";
import { MAX_LENGHT } from "constants/environment";
import { LOGISTICS_SERVICES_MANAGE_PATH } from "constants/paths";
import { localizedStrings } from "constants/localizedStrings";
import { toast } from "react-toastify";
import { isServiceBetweenDayRange, verifyIfUserCanEvaluateService, } from "utils/services";

export default function LogisticsServicesRoute({ history, match }) {

    const dispatch = useDispatch();

    const {
        user
    } = useSelector(state => state.auth);

    const {
        service,
        loadFail,
        loadSuccess,
    } = useSelector(state => state.services);

    const {
        servicePlaces
    } = useSelector(state => state.places);

    const loadService = () => {
        dispatch(
            loadOneService({
                id: match?.params?.id
            })
        )
    }

    const loadOrganizationVehicles = vehicleId => {
        dispatch(
            loadMapVehicles({
                limit: true,
                vehicle_id: vehicleId,
            })
        )
    }

    const loadOrganizationPlaces = (placesIds) => {
        dispatch(
            loadPlaces({
                limit: MAX_LENGHT,
                place_ids: placesIds
            })
        )
    }



    const setIntervalWithinActiveTab = (callback, timeIntervalInMiliSeconds) => {

        const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
            window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

        window.requestAnimationFrame = requestAnimationFrame;


        window.requestAnimationFrame(() => {

            setTimeout(() => {

                callback();

                setIntervalWithinActiveTab(callback, timeIntervalInMiliSeconds);

            }, timeIntervalInMiliSeconds);

        })
    }

    const loadVehiclesOnMap = () => {


        const vehiclesIds = service.vehicles.map(v => v.vehicle_id);

        const mapReloadInterval = 15 * 1000;

        loadOrganizationVehicles(vehiclesIds);

        setIntervalWithinActiveTab(() => {

            loadOrganizationVehicles(vehiclesIds);

        }, mapReloadInterval)

    };

    useEffect(() => {
        const hasPlaces = service?.places?.length > 0;

        const hasStartDate = service?.end_date;

        const hasEndDate = service?.start_date;

        if (hasPlaces) {

            const placesIds = service.places.map(p => p.place_id);

            const hasPlaces = placesIds?.length > 0;

            const serviceHasPlaces = servicePlaces?.length > 0;

            if (hasPlaces && !serviceHasPlaces) loadOrganizationPlaces(placesIds);

        }
        if (hasStartDate && hasEndDate) {

            const isServiceOnGoing = isServiceBetweenDayRange({
                service
            });

            const hasVehicles = service?.vehicles?.length > 0;

            if (hasVehicles && isServiceOnGoing) loadVehiclesOnMap();
        }


        // eslint-disable-next-line
    }, [loadSuccess]);

    useEffect(() => {

        loadService();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (loadFail) {
            toast.error(localizedStrings.logisticsServices.serviceUnavailable);
            history.push(LOGISTICS_SERVICES_MANAGE_PATH);
        }

        if (loadSuccess) {

            const urlToRedirectUser = verifyIfUserCanEvaluateService({
                service,
                user,
            })

            if (urlToRedirectUser) history.push(urlToRedirectUser)

        }
        // eslint-disable-next-line
    }, [loadFail, loadSuccess]);


    return (
        <Header
            type="route"
            contentStyle={{
                background: "#F8F8FB"
            }}
        >
            <UserServiceRoute />
        </Header>
    );
}
