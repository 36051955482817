import React, { useEffect } from "react";
import {
    Header,
    ServiceDetails,
} from "containers";
import { useDispatch, useSelector } from "react-redux";
import {
    loadOneService, loadPlaces, loadVehicles, setPlacesToService, 
} from "store/modules";
import { MAX_LENGHT } from "constants/environment";
import { LOGISTICS_SERVICES_MANAGE_PATH } from "constants/paths";
import { localizedStrings } from "constants/localizedStrings";
import { toast } from "react-toastify";
import { verifyIfUserCanEvaluateService, } from "utils/services";

export default function LogisticsServicesDetails({ history, match }) {

    const dispatch = useDispatch();

    const {
        user
    } = useSelector(state => state.auth);

    const {
        loadFail,
        loadSuccess,
        service,
        servicePlaces
    } = useSelector(state => state.services)

    const {
        places
    } = useSelector(state => state.places)

    const loadService = () => {
        dispatch(
            loadOneService({
                id: match?.params?.id
            })
        )
    }
    const loadOrganizationPlaces = (placesIds = []) => {
        dispatch(
            loadPlaces({
                limit: MAX_LENGHT,
                organization_id: user.organization_id,
                place_ids: placesIds,
            })
        )
    }

    const loadOrganizationVehicles = () => {
        dispatch(
            loadVehicles({
                limit: MAX_LENGHT,
                organization_id: user.organization_id,
            })
        )
    }

    useEffect(() => {

        loadOrganizationVehicles();
        loadService();

        // eslint-disable-next-line
    }, [match?.params?.id]);

    useEffect(() => {
        const placesIds = service?.places?.map(p => p.place_id);

        const hasPlaces = placesIds?.length > 0;

        const serviceHasPlaces = servicePlaces?.length > 0;

        if (hasPlaces && !serviceHasPlaces) loadOrganizationPlaces(placesIds);

        // eslint-disable-next-line
    }, [loadSuccess, servicePlaces]);

    useEffect(() => {

        const hasPlaces = places?.length > 0;

        const serviceHasPlaces = servicePlaces?.length > 0;

        if (hasPlaces && !serviceHasPlaces) {
            dispatch(setPlacesToService({
                places,
                service,
                user,
            }))
        }

        // eslint-disable-next-line
    }, [places, servicePlaces])

    useEffect(() => {

        if (loadFail) {
            toast.error(localizedStrings.logisticsServices.serviceUnavailable);
            history.push(LOGISTICS_SERVICES_MANAGE_PATH);
        }

        if (loadSuccess) {

            const urlToRedirectUser = verifyIfUserCanEvaluateService({
                service,
                user,
            })

            if (urlToRedirectUser) history.push(urlToRedirectUser)

        }
        // eslint-disable-next-line
    }, [loadFail, loadSuccess]);

    return (
        <Header
            type="details"
            contentStyle={{
                background: "#F8F8FB"
            }}
        >
            <ServiceDetails />

        </Header>
    );
}
