export default {
    welcome: "Seja bem-vindo(a)",
    loginInstructions: "Para acessar a sua conta, solicite a identificação e chave de acesso à empresa responsável por organizar sua rota.",
    typeIdentification: "Digite sua identificação",
    typeAnyIdentification: "Digite a identificação",
    typeAccessKey: "Digite a sua chave de acesso",
    accessKey: "Chave de Acesso",
    searchForServices: "Buscar Serviços",
    developedByFullText: "Desenvolvido pela Contele Rastreadores  -  Todos os direitos reservados "
}
