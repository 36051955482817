import React, { useEffect, useState } from 'react'
import { Text } from 'components'
import { DepartureIdentificationStyle } from './style'
import { useSelector } from 'react-redux';
import QRCode from 'qrcode'
import { localizedStrings } from 'constants/localizedStrings';

export default function DepartureIdentification() {
    const {
        service
    } = useSelector(state => state.services);

    const {
        user: {
            id: userId
        }
    } = useSelector(state => state.auth);

    const [qrcodeUrl, setQrcodeUrl] = useState("")


    useEffect(() => {

        if (userId) {
            const extraSpaces = new Array(60).join(" ");

            const idWithExtraSpaces = String(extraSpaces + userId + extraSpaces);

            const qrcodeConfig = { errorCorrectionLevel: 'H' };


            QRCode.toDataURL(idWithExtraSpaces, qrcodeConfig, (err, url) => {
                setQrcodeUrl(url);
            });
        }
        // eslint-disable-next-line
    }, [userId])

    return (
        !service?.id ? null :
            <DepartureIdentificationStyle>
                <Text
                    color="#1A237A"
                    textTransform="uppercase"
                    fontSize="18px"
                    fontWeight="bold"
                >
                    {localizedStrings.logisticsServices.qrcodeDepartureAndArrival}
                </Text>
                <img src={qrcodeUrl} alt="qrcode place id" />
            </DepartureIdentificationStyle>
    )
}
