import React, { useEffect, useState, useMemo } from 'react'
import {
    Text,
    Link, LoadingSpin
} from 'components'
import {
    VehicleDetailsDiv,
    VehicleCard,
    VehicleInformations,
    VehicleData,

} from './style';
import { localizedStrings } from 'constants/localizedStrings';
import { useDispatch, useSelector } from 'react-redux';
import { cancelException } from 'store/modules';

export default function DepartureIdentification() {

    const dispatch = useDispatch();

    const {
        service,
        updateLoading,
        serviceProgress,
        updateSuccess,
    } = useSelector(state => state.services);

    const {
        vehicles
    } = useSelector(state => state.vehicles);

    const {
        user: {
            id: userId
        }
    } = useSelector(state => state.auth);

    const [serviceVehicle, setServiceVehicle] = useState(false);

    useEffect(() => {

        const hasVehicles = vehicles?.length > 0

        if (hasVehicles) {

            const vehiclesIdsFromService = service?.vehicles?.map?.(vehicle => +vehicle.vehicle_id) || [];

            const vehiclesFromService = vehicles.filter(vehicle => vehiclesIdsFromService.includes(+vehicle.id));

            setServiceVehicle(vehiclesFromService.shift());

        }
        // eslint-disable-next-line
    }, [vehicles]);

    const placeHasNotCancelledOrEmbarked = place => +place?.place_id === +userId;

    const placeCanCancelService = useMemo(
        () => {
            const hasProgress = Array.isArray(serviceProgress) && serviceProgress.length > 0;

            if (!hasProgress) return false;

            const userHasCancelledOrEmbarked = !!serviceProgress
                .filter(placeHasNotCancelledOrEmbarked)
                .length;

            return !userHasCancelledOrEmbarked;
        },
        // eslint-disable-next-line
        [serviceProgress, userId]
    );

    const showCancelButton = useMemo(
        () => {
            const hasCancelledNow = !!updateSuccess;

            return placeCanCancelService && !hasCancelledNow && service?.id;
        },
        // eslint-disable-next-line
        [placeCanCancelService, service?.id, updateSuccess,]
    );

    return (
        <VehicleDetailsDiv>
            {
                serviceVehicle &&
                <VehicleCard >
                    <VehicleInformations>
                        <VehicleData>
                            <div>
                                {
                                    serviceVehicle?.plate_number &&
                                    <Text
                                        fontSize="24px"
                                        color="#000"
                                        fontWeight="bold"
                                    >
                                        {serviceVehicle?.plate_number?.toUpperCase?.()}
                                    </Text>
                                }
                            </div>
                            <div>
                                {
                                    serviceVehicle?.model && serviceVehicle?.name &&
                                    <Text
                                        fontSize="14px"
                                        color="#444"
                                        fontWeight="500"
                                    >
                                        {
                                            [
                                                serviceVehicle?.model?.toUpperCase?.(),
                                                serviceVehicle?.name,
                                            ].join(", ")
                                        }
                                    </Text>
                                }
                            </div>
                            <div>
                                {
                                    service.driver_name &&
                                    <Text
                                        fontSize="14px"
                                        color="#444"
                                        fontWeight="500"
                                    >
                                        {
                                            localizedStrings.logisticsServices.driver + ": " + service.driver_name
                                        }
                                    </Text>
                                }
                            </div>
                        </VehicleData>
                    </VehicleInformations>
                </VehicleCard>
            }
            {
                showCancelButton &&
                <Link
                    padding="10px"
                    textAlign="center"
                    textTransform="uppercase"
                    border="1px solid #FF3D3D"
                    margin="25px 0 10px 0"
                    borderRadius="6px"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    position="relative"
                    href="#"
                    onClick={() => {

                        const [firstDriver] = service?.drivers;

                        const [firstVehicle] = service?.vehicles;

                        dispatch(cancelException({
                            service_id: service?.id,
                            driver_id: firstDriver?.driver_id,
                            vehicle_id: firstVehicle?.vehicle_id,
                            place_id: userId,
                        }))
                    }}
                >
                    {updateLoading && <LoadingSpin
                        show={updateLoading}
                        weight={"5px"}
                        size={"20px"}
                        position="absolute"
                        left="10px"
                        backColor="#FF3D3D"
                        frontColor="transparent"
                    />}
                    <Text
                        as="div"
                        display="flex"
                        alignItems="center"
                        fontSize="14px"
                        color="#FF3D3D"
                        fontWeight="bold"
                    >
                        {localizedStrings.logisticsServices.cancel}
                    </Text>
                </Link>
            }
        </VehicleDetailsDiv>
    )
}
